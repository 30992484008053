import React from "react";
import { useHistory } from 'react-router-dom';
import {Chip, makeStyles} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CustomButton from "../../../../_metronic/layout/components/common/CustomButton";
// import { useSelector } from "react-redux";
// import { getFileApiCall } from "../../../helpers";

const FileField = ({
    field,
    value,
    handleUploadFile,
    handleDelete,
    fromApplicationForm,
    fullWidthButton,
    error,
    readonly
}) => {
    const history = useHistory();
    const styles = useStyles();
    const { label, parameters, required, name, type, id, requiredLabel } = field;
    const { multiple, deletable } = parameters;
    const fieldId = name ? name : id;

    const onPillClick = () => {
      history.push('cv');
    }

    const renderChip = () => {
      if (!value) {
        return null
      }
      if (multiple && value.length > 0) {
        return value.map((val, i) => {
          return (
              <Chip 
                key={i}
                className={styles.chip} 
                label={val && val.name}
                variant="outlined" 
                onDelete={() => deletable && handleDelete(i, multiple, fieldId)}
                clickable={value && value.id ? true : false}
                onClick={onPillClick}
              />
          )
        })
      }

      return (
        <Chip 
          className={styles.chip} 
          label={value && value.name}
          variant="outlined" 
          onDelete={deletable && !required ? () =>  handleDelete(null, multiple, fieldId) : null}
          clickable={value && value.id ? true : false}
          onClick={onPillClick}
        />
      )
    }

    return (
        <div className={`form-group ${fromApplicationForm ? "" : "row"}`}>
          {label && (
            <label className="col-xl-3 col-lg-3 col-form-label">
              {label}
              {required && (<span className="required-identification"> *</span>)}
            </label>
          )}
          <div className={fromApplicationForm ? `` : `col-lg-9 col-xl-6`}>
            {
            !readonly &&
            <div className="fv-plugins-icon-container">
              <input name={name} type={type} id={id} style={{ display: "none" }} onChange={(e)=>handleUploadFile(e, field.name, multiple)} />
              <label htmlFor={id} style={{width: fullWidthButton ? "100%" : "auto"}}>
                <CustomButton 
                  fullWidth={fullWidthButton ? true : false} 
                  component="span" 
                  startIcon={<CloudUploadIcon/>}
                >
                  Upload file
                </CustomButton>
              </label>
              {fromApplicationForm && (
                <label className="col-xl-8 col-lg-8 col-form-label">{value && value.name}</label>
              )}
            </div>
            }
            {required && !value ? <div className="invalid-feedback display-block">{`${requiredLabel ? requiredLabel : label} is required!`}</div> : null}
            {!fromApplicationForm && renderChip()}
            {error && (
              <span  className="form-text error-text">
                {error}
              </span>
            )}
            {
            !readonly &&
            <span className="form-text text-muted">{`Allowed file types: pdf, doc, docx. Maximum file size: ${process.env.REACT_APP_FILE_SIZE} mb.`}</span>
            }
          </div>
        </div>
        )
};

export default FileField;

const useStyles = makeStyles(theme => ({
    chip: {
      margin: "5px 10px 5px 0"
    },
  }));
  