import React from "react";
import { makeStyles, InputBase } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { orangeColor, whiteColor } from "../../../../_metronic/layout";
import CloseIcon from "@material-ui/icons/Close";
import { isMobilePhonePortrait } from "../../../helpers";

const SearchBar = ({ handleChangeTerm, removeSearchTerm, value, doSearching }) => {
  const classes = useStyles();
  const styles = useStyles();

  const handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      doSearching();
    }
  }

  return (
    <div className={styles.search}>  
      <InputBase
        value={value}
        startAdornment={
          <SearchIcon 
            onTouchStart={() => doSearching()} 
            onMouseDown={() => doSearching()}
          />
        }
        endAdornment={<CloseIcon className={`${classes.closeIcon} ${value ? "" : classes.hiddenCloseIcon}`} fontSize="small" onClick={removeSearchTerm} />}
        onChange={handleChangeTerm}
        onKeyPress={handleKeyPress}
        placeholder="Type a position or a keyword"
        classes={{
          adornedEnd: styles.adornedEndRoot,
          root: styles.inputRoot,
          input: styles.inputInput
        }}
        inputProps={{ "aria-label": "search" }}
      />
    </div>
  );
};

export default SearchBar;

const useStyles = makeStyles(theme => ({
  search: {
    alignSelf: "center",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#e0e0e0",
    display: "flex",
    alignItems: "center",
    transition: "all .2s linear",
    color: orangeColor,
    "& input": {
      color: "#666666",
      opacity:  0.5,
      '&::placeholder': {
        opacity: 1
      },
    },
    "&:hover": {
      backgroundColor: orangeColor,
      color: `${theme.palette.primary.light} !important`,
      "& input": {
        color: "white",
        opacity:  1,
      }
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto"
    }
  },
  inputInput: {
    color: "inherit",
    // padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    // paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: "all .2s linear",
    width: isMobilePhonePortrait() ? "80%" : "26ch",
    fontWeight: "bold",
    "&:focus": {
      width: isMobilePhonePortrait() ? "80%" : "25ch"
    },
    margin: "0 5px"
  },
  hoveredInput: {
    color: theme.palette.primary.light,
    '&::placeholder': {
      color: theme.palette.primary.light,
      opacity:  1
    }
  },
  adornedEndRoot: {
    color: "inherit",
    margin: "0 10px",
    padding: 5,
    "& svg:hover": {
      cursor: "pointer"
    },
    justifyContent: "space-between",
    width: "100% !important"
  },
  closeIcon: {
    transition: "all .1s linear",
    "&:hover": {
       border: `2px solid ${whiteColor}`,
       fontSize: 20,
       fontWeight: 900,
       borderRadius: 10,
    },
    visibility: "visible",
    marginLeft: "auto"
  },
  hiddenCloseIcon: {
    visibility: "hidden"
  },
  inputRoot: {
    width: isMobilePhonePortrait() ? "100%" : "auto"
  }
}));
