import React from "react";
import { useSubheader } from "../../../_metronic/layout";
import { ProfileCard } from "./components/ProfileCard";

export default function UserProfilePage() {
  const suhbeader = useSubheader();
  suhbeader.setTitle("User profile");

  return <ProfileCard />;
}
