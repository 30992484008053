const SET_JOB_POSITION = "set-job-position";
const SET_JOB_APPS = "set-job-apps";
const SET_JOB_POSITIONS = "set-job-positions";
const SET_JOB_CATEGORIES = "set-job-categories";
const SET_JOB_LOCATIONS = "set-job-locations";
const SET_JOB_WORKING_MODES = "set-job-working-modes";
const SET_JOBS_PAGE = "set-jobs-page";
const SET_JOB_APPS_PAGE = "set-job-apps-page";
const SET_JOB_LAST_ID = "set-job-last-id";

const setJobPosition = payload => ({
  type: SET_JOB_POSITION,
  payload
});

const setJobsPage = payload => ({
  type: SET_JOBS_PAGE,
  payload
});

const setJobAppsPage = payload => ({
  type: SET_JOB_APPS_PAGE,
  payload
});

const setJobApplications = payload => ({
  type: SET_JOB_APPS,
  payload
});

const setJobPositions = payload => ({
  type: SET_JOB_POSITIONS,
  payload
});

const setJobLastId = payload => ({
  type: SET_JOB_LAST_ID,
  payload
});

const setJobCategories = payload => ({
  type: SET_JOB_CATEGORIES,
  payload
});

const setJobLocations = payload => ({
  type: SET_JOB_LOCATIONS,
  payload
});

const setJobWorkingModes = payload => ({
  type: SET_JOB_WORKING_MODES,
  payload
});

//ASYNC ACTION CREATORS
const PENDING_JOB_POSITIONS = "pending-job-positions";
const FULFILLED_JOB_POSITIONS = "fulfilled-job-positions";
const FAILED_JOB_POSITIONS = "failed-job-positions";

const PENDING_JOB_POSITION = "pending-job-position";
const FULFILLED_JOB_POSITION = "fulfilled-job-position";
const FAILED_JOB_POSITION = "failed-job-position";

const PENDING_JOB_APPS = "pending-job-apps";
const FULFILLED_JOB_APPS = "fulfilled-job-apps";
const FAILED_JOB_APPS = "failed-job-apps";

const PENDING_JOB_CATEGORIES = "pending-job-categories";
const FULFILLED_JOB_CATEGORIES = "fulfilled-job-categories";
const FAILED_JOB_CATEGORIES = "failed-job-categories"

const PENDING_JOB_LOCATIONS = "pending-job-locations";
const FULFILLED_JOB_LOCATIONS = "fulfilled-job-locations";
const FAILED_JOB_LOCATIONS= "failed-job-locations"

const PENDING_JOB_MODES = "pending-job-modes";
const FULFILLED_JOB_MODES = "fulfilled-job-modes";
const FAILED_JOB_MODES = "failed-job-modes"

const PENDING_PROFILE_SCHEMA = "pending-profile-schema";
const FULFILLED_PROFILE_SCHEMA = "fulfilled-profile-schema";
const FAILED_PROFILE_SCHEMA = "failed-profile-schema";

const PENDING_LATEST_POSTS = 'pending-latest-posts';
const FULFILLED_LATEST_POSTS = 'fulfilled-latest-posts';
const FAILED_LATEST_POSTS = 'failed-latest-posts';

//ASYNC ACTIONS
const pendingJobPositions = payload => ({
  type: PENDING_JOB_POSITIONS,
  payload
});

const fulfilledJobPositions = payload => ({
  type: FULFILLED_JOB_POSITIONS,
  payload
});

const failedJobPositions = payload => ({
  type: FAILED_JOB_POSITIONS,
  payload
});

const pendingJobPosition = payload => ({
  type: PENDING_JOB_POSITION,
  payload
});

const fulfilledJobPosition = payload => ({
  type: FULFILLED_JOB_POSITION,
  payload
});

const failedJobPosition = payload => ({
  type: FAILED_JOB_POSITION,
  payload
});


const pendingJobApps = payload => ({
  type: PENDING_JOB_APPS,
  payload
});

const fulfilledJobApps = payload => ({
  type: FULFILLED_JOB_APPS,
  payload
});

const failedJobApps = payload => ({
  type: FAILED_JOB_APPS,
  payload
});

const pendingJobCategories = payload => ({
  type: PENDING_JOB_CATEGORIES,
  payload
});

const fulfilledJobCategories = payload => ({
  type: FULFILLED_JOB_CATEGORIES,
  payload
});

const failedJobCategories = payload => ({
  type: FAILED_JOB_CATEGORIES,
  payload
});

const pendingJobLocations = payload => ({
  type: PENDING_JOB_LOCATIONS,
  payload
});

const fulfilledJobLocations = payload => ({
  type: FULFILLED_JOB_LOCATIONS,
  payload
});

const failedJobLocations = payload => ({
  type: FAILED_JOB_LOCATIONS,
  payload
});

const pendingJobModes = payload => ({
  type: PENDING_JOB_MODES,
  payload
});

const fulfilledJobModes = payload => ({
  type: FULFILLED_JOB_MODES,
  payload
});

const failedJobModes = payload => ({
  type: FAILED_JOB_MODES,
  payload
});

const pendingProfileSchema = payload => ({
  type: PENDING_PROFILE_SCHEMA,
  payload
});

const fulfilledProfileSchema = payload => ({
  type: FULFILLED_PROFILE_SCHEMA,
  payload
});

const failedProfileSchema = payload => ({
  type: FAILED_PROFILE_SCHEMA,
  payload
});

const pendingLatestPosts = payload => ({
  type: PENDING_LATEST_POSTS,
  payload
});

const fulfilledLatestPosts = payload => ({
  type: FULFILLED_LATEST_POSTS,
  payload
});

const failedLatestPosts = payload => ({
  type: FAILED_LATEST_POSTS,
  payload
});

const apiCall = async (dispatch, pendingAction, fulfilledAction, failedAction, url, bearerToken) => {
  try {
    dispatch(pendingAction());
    const apiResponse = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bearerToken}`,
        "Cache-Control": "no-cache"
      }
    });

    const apiResponseJSON = await apiResponse.json();

    const apiData = apiResponseJSON;

    if (!apiResponse.ok) {
      const payload = apiResponseJSON.data ? apiResponseJSON.data[Object.keys(apiResponseJSON.data)[0]] : apiResponseJSON.message;

      throw new Error(payload);
    } else {
      dispatch(fulfilledAction(apiData));
    }
  } catch (error) {
    dispatch(failedAction(error.message));
  }
};

const fetchJobPositions = ({ access_token }, itemsPerPage, page, search, categories = [], locations = null, modes = null) => {
  return async dispatch => {
    await apiCall(
      dispatch,
      pendingJobPositions,
      fulfilledJobPositions,
      failedJobPositions,
      `${process.env.REACT_APP_API_URL}/jobs/?per_page=${itemsPerPage ? itemsPerPage : 20}&page=${page ? page : 1}${search ? `&search=${search}` : ""}${(categories && categories.length) ? `&categoryIds=[${categories}]` : ""}${locations ? `&locations=${locations}` : ""}${modes ? `&modes=${modes}` : ""}`,
      access_token
    );
  };
};

const fetchJobPosition = ({ access_token }, jobId) => {
  return async dispatch => {
    await apiCall(
      dispatch,
      pendingJobPosition,
      fulfilledJobPosition,
      failedJobPosition,
      `${process.env.REACT_APP_API_URL}/jobs/${jobId}`,
      access_token
    );
  };
};

const fetchJobApplications = ({ access_token }, itemsPerPage, page) => {
  return async dispatch => {
    await apiCall(
      dispatch,
      pendingJobApps,
      fulfilledJobApps,
      failedJobApps,
      `${process.env.REACT_APP_API_URL}/jobs/applications/?per_page=${itemsPerPage ? itemsPerPage : 5}&page=${page ? page : 1}`,
      access_token
    );
  };
};

const fetchJobCategories = ({ access_token }) => {
  return async dispatch => {
    await apiCall(
      dispatch,
      pendingJobCategories,
      fulfilledJobCategories,
      failedJobCategories,
      `${process.env.REACT_APP_API_URL}/jobs/categories`,
      access_token
    );
  };
};

const fetchJobLocations = ({ access_token }) => {
  return async dispatch => {
    await apiCall(
      dispatch,
      pendingJobLocations,
      fulfilledJobLocations,
      failedJobLocations,
      `${process.env.REACT_APP_API_URL}/jobs/locations`,
      access_token
    );
  };
};

const fetchJobModes = ({ access_token }) => {
  return async dispatch => {
    await apiCall(
      dispatch,
      pendingJobModes,
      fulfilledJobModes,
      failedJobModes,
      `${process.env.REACT_APP_API_URL}/jobs/modes`,
      access_token
    );
  };
};

const fetchLatestPosts = ({ access_token }) => {
  return async dispatch => {
    await apiCall(
        dispatch,
        pendingLatestPosts,
        fulfilledLatestPosts,
        failedLatestPosts,
        `${process.env.REACT_APP_API_URL}/latest-posts`,
        access_token
    )
  }
}

const fetchProfileSchema = ({ access_token }) => {
  return async dispatch => {
    await apiCall(
      dispatch,
      pendingProfileSchema,
      fulfilledProfileSchema,
      failedProfileSchema,
      `${process.env.REACT_APP_API_URL}/profile/schema`,
      access_token
    );
  };
};

export { 
  setJobPosition, 
  setJobsPage,
  setJobAppsPage,
  setJobLastId,
  fetchJobPositions,
  fetchJobPosition, 
  fetchJobCategories,
  setJobCategories,
  fetchJobModes,
  fetchLatestPosts,
  setJobWorkingModes,
  fetchJobLocations,
  setJobLocations, 
  setJobApplications, 
  setJobPositions, 
  fetchJobApplications, 
  fetchProfileSchema, 
  fulfilledProfileSchema,
  fulfilledJobPositions
};

export {
  SET_JOB_APPS,
  SET_JOBS_PAGE,
  SET_JOB_APPS_PAGE,
  SET_JOB_POSITION,
  SET_JOB_POSITIONS,
  SET_JOB_LAST_ID,
  SET_JOB_LOCATIONS,
  SET_JOB_CATEGORIES,
  SET_JOB_WORKING_MODES,
  PENDING_JOB_POSITIONS,
  FULFILLED_JOB_POSITIONS,
  FAILED_JOB_POSITIONS,
  PENDING_JOB_POSITION,
  FULFILLED_JOB_POSITION,
  FAILED_JOB_POSITION,
  PENDING_JOB_APPS,
  FULFILLED_JOB_APPS,
  FAILED_JOB_APPS,
  PENDING_PROFILE_SCHEMA,
  FULFILLED_PROFILE_SCHEMA,
  FAILED_PROFILE_SCHEMA,
  PENDING_JOB_CATEGORIES,
  FULFILLED_JOB_CATEGORIES,
  FAILED_JOB_CATEGORIES,
  PENDING_JOB_LOCATIONS,
  FULFILLED_JOB_LOCATIONS,
  FAILED_JOB_LOCATIONS,
  PENDING_JOB_MODES,
  FULFILLED_JOB_MODES,
  FAILED_JOB_MODES,
  PENDING_LATEST_POSTS,
  FULFILLED_LATEST_POSTS,
  FAILED_LATEST_POSTS,
};
