import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SwipeCore, { Autoplay, Navigation, Pagination} from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css'
import 'swiper/components/pagination/pagination.min.css'
import CustomButton from "../../../../_metronic/layout/components/common/CustomButton";
import { fetchLatestPosts } from "../../../../redux/modules/data/dataActions";

SwipeCore.use([Navigation, Pagination, Autoplay]);
export const LatestPostsSlider = () => {
    const { access_token } = useSelector(state => state.auth);
    const latestPosts = useSelector(state => state.data.latestPosts);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchLatestPosts({ access_token }, ));
    }, [
        access_token,
        dispatch
    ]);

    return (
        <div className="latest-posts-slider">
            { latestPosts?.length ?
                <Swiper
                    spaceBetween={50}
                    slidesPerView={1}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Autoplay, Pagination]}
                    autoHeight={true}
                >
                    { latestPosts.map((post, index) => (
                        <SwiperSlide key={index}>
                            <div className="slide-inner">
                                { post.thumbnail && (
                                    <div className="slide-inner__image">
                                        <img className="w-auto h-auto" src={ post.thumbnail } alt=""/>
                                    </div>
                                )}

                                <div className="slide-inner__content">
                                    <h5>
                                        { post.title }
                                    </h5>

                                    <p>
                                        { post.description }
                                    </p>

                                    <span>
                                        <b>
                                            { post.author }
                                        </b>

                                        { new Date(post.created_at).toISOString().slice(0, 10) }
                                    </span>

                                    <div className="actions">
                                        <CustomButton>
                                            <a href={post.url} target="_blank" rel="noopener noreferrer">
                                                Read more
                                            </a>
                                        </CustomButton>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                : <h2>Loading</h2>
            }
        </div>
    )
}