/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import NatekLogoPuzzle from "../../../../../src/_metronic/_images/NATEK-logo-puzzle.png";
import NateDefaultAvatar from "../../../../../src/_metronic/_images/NATEK-mark-color.png";

export const ProfileCard = () => {
  const user = useSelector(({ auth }) => auth.user);

  const renderLinkedInProfile = profileLink => {
    const linkedInProfile = profileLink;
    // const formatted = linkedInProfile.length > 20 && linkedInProfile.slice(0, 20);

    // return formatted ? `${formatted}...` : linkedInProfile;
    return linkedInProfile;
  };
  return (
    <>
      {user && (
        <div className="metronic_container metronic_user-profile">
          <div className="card card-custom card-stretch">
            {/* begin::Header */}
            <div className="card-header border-0">
              <h3 className="card-title font-weight-bolder text-dark">My Profile</h3>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className="card-body pt-4">
              {/* begin::User */}
              <div className="d-flex align-items-center">
                <div className="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                  {/* <div className="symbol-label" style={{ backgroundImage: `url(${user.profile_photo ? `${process.env.REACT_APP_STORAGE_URL}/${user.profile_photo.hash}` : NateDefaultAvatar})` }}></div> */}
                  <div className="symbol bg-white-o-15">
                    <img alt="Pic" className="hidden metronic_user-img" src={user.profile_photo ? `${process.env.REACT_APP_STORAGE_URL}/${user.profile_photo.hash}` : NateDefaultAvatar} />
                  </div>
                  <i className="symbol-badge bg-success"></i>
                </div>
                <div>
                  <span className="font-weight-bolder font-size-h5 text-dark-75">
                    {user.first_name} {user.family_name}
                  </span>
                  {/* <div className="text-muted">Occupation {user.occupation}</div> */}
                </div>
              </div>
              {/* end::User */}
              {/* begin::Contact */}
              <div className="py-9">
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <span className="font-weight-bold mr-2">Email:</span>
                  <span className="text-muted">{user.email}</span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <span className="font-weight-bold mr-2">Phone:</span>
                  <span className="text-muted">{user.phone}</span>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <span className="font-weight-bold mr-2">LinkedIn:</span>
                  {user.linkedin_url && (
                    <a href={user.linkedin_url} className="text-muted text-hover-primary no-wrap-text linked-in-url" target="_blank" rel="noopener noreferrer">
                      {renderLinkedInProfile(user.linkedin_url)}
                    </a>
                  )}
                </div>
              </div>
              {/* end::Contact */}
              {/* begin::Nav */}
              <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                <div className="navi-item mb-2 metronic_details-container">
                  <NavLink to="/profile" className="navi-link py-4" activeClassName="active">
                    <span className="navi-icon mr-2">
                      <img src={NatekLogoPuzzle} alt="Natek Logo Puzzle" className="metronic_nate-logo-puzzle" />
                    </span>

                    <span className="navi-text font-size-lg">Profile details </span>
                  </NavLink>
                </div>
              </div>
              {/* end::Nav */}
            </div>
            {/* end::Body */}
          </div>
        </div>
      )}
    </>
  );
};
