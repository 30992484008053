import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { Popover, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    candidateDetailsTooltip: {
        fontSize: '1.2rem',
        padding: theme.spacing(2),
        maxWidth: '300px'
    },
    orangeIcon: {
        color: '#eb6438',
        cursor: 'pointer',
    },
    popoverContent: {
        padding: theme.spacing(2),
    },
}));

export default function PopoverCandidateProfiles() {
    const classes = useStyles();
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const emailLink = 'mailto:hrjira@natek.eu';

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setPopoverOpen(true);
    };

    const handlePopoverContentMouseEnter = () => {
        setPopoverOpen(true);
    };

    const handlePopoverContentMouseLeave = () => {
        setPopoverOpen(false);
    };

    return (
        <div>
            <HelpIcon
                className={classes.orangeIcon}
                onMouseEnter={handlePopoverOpen}
                ref={(node) => setAnchorEl(node)}
            />
            <Popover
                open={popoverOpen}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div
                    className={classes.popoverContent}
                    onMouseEnter={handlePopoverContentMouseEnter}
                    onMouseLeave={handlePopoverContentMouseLeave}
                >
                    <Typography className={classes.candidateDetailsTooltip}>
                        If you are currently employed or have been in the past and need to update your
                        personal information, kindly reach out to us at {' '}
                        <a href={emailLink}>hrjira@natek.eu</a>.
                    </Typography>
                </div>
            </Popover>
        </div>
    );
}
