/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import ExternalRoute from "./ExternalRoute";
import HomePage from "./pages/HomePage";
import { setJobLastId } from "../redux/modules/data/dataActions";
import JobsPage from "./pages/JobsPage";

export const Routes = () => {
  const { isAuthorized, redirectPath } = useSelector(({ auth }) => ({
    isAuthorized: auth.user != null,
    redirectPath: auth.redirectPath ? auth.redirectPath : "/"
  }));

  // Set job last id
  if (window) {
    const currentUrl = window.location.href;
    if (currentUrl.includes('apply') && currentUrl.includes('jobs')) {
      setJobLastId(currentUrl.split('/')[4]);
    }
  }

  return (
    <Switch>
      {/*check external routes before auth and base pages*/}
      <ExternalRoute path="/auth/account/delete/confirm/:deleteHash/:expires/:deleteSignature">
        <Layout>
          <HomePage />
        </Layout>
      </ExternalRoute>
      <ExternalRoute exact path="/jobs/:jobId/apply">
        <Layout>
          <JobsPage />
        </Layout>
      </ExternalRoute>
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to={redirectPath} />
      )}

      <Route path="/error" component={ErrorsPage} />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  );
};
