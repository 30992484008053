import React from "react";
import { DialogActions, DialogContent, Dialog, /* DialogTitle,  */makeStyles } from "@material-ui/core";
import CustomButton from "./CustomButton";

const ClosePopover = ({ isOpened, handleClosePopover, content }) => {
  const styles = useStyles();

  return (
    <Dialog
      classes={{ paper: styles.paper }}
      onClose={handleClosePopover}
      aria-labelledby="metronic_refer-dialog"
      open={isOpened}
      className="metronic_custom-dialog"
    >
      {/* <DialogTitle id="metronic_refer-dialog" classes={{ root: styles.dialogTitleRoot }}>
        <p className={styles.title}>{title}</p>
      </DialogTitle> */}
      <DialogContent>
        <p>
          {content}
        </p>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={handleClosePopover}>Close</CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default ClosePopover;

const useStyles = makeStyles(theme => ({
  paper: {
    width: 500,

    "& div": {
      width: "100%"
    }
  },
  dialogTitleRoot: {
    padding: "16px 24px 0px"
  },
  title: {
    fontSize: 17,
    fontWeight: 700
  },
}));