import React, { useState } from "react";
import { Grid, DialogActions, DialogContent, Dialog, DialogTitle, InputLabel, makeStyles } from "@material-ui/core";
import CustomButton from "../../../../_metronic/layout/components/common/CustomButton";
import { TextField } from "@material-ui/core";
import FileField from "./FileField";
import { useDispatch, useSelector } from "react-redux";
import { linkedinRegex, linkedinValidationMessage, validateFile } from "../../../helpers";
import { setDialogNotificationText } from "../../../../redux/modules/notifications/notificationsActions";
import { SUCCESSFUL_REFERENCE_TEXT } from "../../../constants";

const ReferPopover = ({ isOpened, handleCloseReferPopover, jobId }) => {
  const styles = useStyles();
  const [name, setName] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [uploadedCv, setUploadedCv] = useState(null);
  const [error, setError] = useState("");
  const [nameError, setNameError] = useState("");
  const [linkedinError, setLinkedinError] = useState("");
  const [fileError, setFileError] = useState(null);
  const { access_token } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const handleUploadFile = (e) => {
    const updatedCv = e.target.files[0];
    if (!updatedCv) return;
    const {isFileValid, fileErr} = validateFile(updatedCv, "cv");
    if (isFileValid) {
      setUploadedCv(updatedCv);
      setFileError("");
    } else {
      setFileError(fileErr);
    }
  };

  const validateData = () => {
    let err = "";
    if (!name) {
      err = 'Name field cannot be empty!';
    }
    if (!uploadedCv && !linkedin) {
      const dataError = "Please add CV or LinkedIn URL!"
      err = err ? err.concat(` ${dataError}`) : dataError;
    }
    if (err) {
      setError(err);
      return false;
    }
    setError("");
    return true;
  }

  const jobReferApiCall = async (url, requestBody) => {
    try {
      const apiResponse = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${access_token}`
        },
        //body: JSON.stringify(requestBody)
        body: requestBody
      });
  
      const apiResponseJSON = await apiResponse.json();
  
      const payload = apiResponseJSON.data ? apiResponseJSON.data[Object.keys(apiResponseJSON.data)[0]] : apiResponseJSON.message;
      if (!apiResponse.ok) {
        setError(payload);
      } else {
        //dispatch(setCustomNotificationType("success"));
        //dispatch(setCustomNotificationText(payload));
        dispatch(setDialogNotificationText(SUCCESSFUL_REFERENCE_TEXT));
        return payload;
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const handleApplyRefer = async () => {
    const isValidData = validateData();
    if (!isValidData) return;

    const referJobformData = new FormData();
    referJobformData.append("name", name);

    if(linkedin) {
      referJobformData.append("linkedin_url", linkedin);
    }

    if (uploadedCv && uploadedCv instanceof File) {
      referJobformData.append("cv", uploadedCv);
    }

    const url = `${process.env.REACT_APP_API_URL}/jobs/${jobId}/refer`;
    const response = await jobReferApiCall(url, referJobformData);
    if (response) {
      handleCloseReferPopover();
    }
  }

  const onNameChange = (val) => {
    const nameRegex = /^[a-zA-Z-\s]+$/;
    const isValidName = nameRegex.test(val);
    //if is not valid name - set error / else clear error
    if (isValidName) {
      setNameError("");
    } else {
      setNameError("You can not use special characters")
    }
    setName(val);
  }

  const onLinkedinChange = (val) => {
    if (val !== '') {
      const isValidUrl = linkedinRegex.test(val);
      //if is not valid name - set error / else clear error
      if (isValidUrl) {
        setLinkedinError("");
      } else {
        setLinkedinError(linkedinValidationMessage)
      }
    } else {
      setLinkedinError("");
    }
    setLinkedin(val);
  }

  return (
    <Dialog
      classes={{ paper: styles.paper }}
      onClose={handleCloseReferPopover}
      aria-labelledby="metronic_refer-dialog"
      open={isOpened}
      className="metronic_custom-dialog"
    >
      <DialogTitle id="metronic_refer-dialog" classes={{ root: styles.dialogTitleRoot }}>
        <p className={styles.title}>Do you know someone who might be interested in this job?</p>
      </DialogTitle>
      <DialogContent>
        {/* <p>
          Refer a friend when writing us on <CustomHyperlink href="mailto:referral@natek.eu">referral@natek.eu</CustomHyperlink>!
        </p> */}
        {error && (
          <div className="global-warning">
            {error}
          </div>
        )}
        <TextField
          className="mt-4 mb-4"
          label={"Name *"}
          id={"name"}
          color="primary"
          value={name}
          onChange={(e)=>onNameChange(e.target.value)}
          error={nameError ? true : false}
          helperText={nameError}
        />
        <TextField
          className="mt-4 mb-4"
          label={"LinkedIn Url"}
          id={"linkedin_url"}
          color="primary"
          value={linkedin}
          onChange={(e)=>onLinkedinChange(e.target.value)}
          error={linkedinError ? true : false}
          helperText={linkedinError}
        />
        <Grid className={styles.customField}>
          <InputLabel className={styles.customLabel}>ORIGINAL CV</InputLabel>
          <FileField 
            field={{
              parameters: [],
              name: "cv",
              type: "file",
              id: "refer-cv"
            }} 
            value={uploadedCv}
            handleUploadFile={handleUploadFile}
            fromApplicationForm={true}
            error={fileError}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <CustomButton disabled={(nameError || linkedinError) ? true : false} onClick={handleApplyRefer}>Refer</CustomButton>
        <CustomButton onClick={handleCloseReferPopover}>Cancel</CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default ReferPopover;

const useStyles = makeStyles(theme => ({
  paper: {
    width: 500,

    "& div": {
      width: "100%"
    }
  },
  dialogTitleRoot: {
    padding: "16px 24px 0px"
  },
  title: {
    fontSize: 17,
    fontWeight: 700
  },
  customField: {
    marginTop: 33
  },
}));