import React, { useEffect, useRef, useState, useCallback } from "react";
import useCheckDimension from "../../../../_metronic/customHooks/useCheckDimension";
import JobsT from "./JobsT";
import SearchBar from "./SearchBar";
import _debounce from 'lodash/debounce';
import { useSelector, useDispatch } from "react-redux";
import { fetchJobPositions, setJobsPage } from '../../../../redux/modules/data/dataActions'
import { Chip } from "@material-ui/core";

const Jobs = () => {
  const checkDimension = useCheckDimension(992);
  const jobPositions = useSelector(state => state.data.jobPositions);
  const jobCategories = useSelector(state => state.data.jobCategories);
  const jobLocations = useSelector(state => state.data.jobLocations);
  const jobWorkingModes = useSelector(state => state.data.jobWorkingModes);
  const [filteredJobPositions, setFilteredJobPositions] = useState([]);
  //const isTableFixed = filteredJobPositions.length > 5;
  const [searchValue, setSearchValue] = useState("");
  const [categoriesValue, setCategoriesValue] = useState([]);
  const [locationsValue, setLocationValue] = useState([]);
  const [modesValue, setModesValue] = useState("");
  const [jobsPerPage, setJobsPerPage] = useState(50);
  const { access_token } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const hasVal1Changed = useHasChanged(searchValue)
  
  useEffect(()=>{
    setFilteredJobPositions(jobPositions.body ? jobPositions.body : [])
  }, [jobPositions])

  //set header template in case the search returns no data and header info can't be extracted from it
  const headerTemplate = ["title", "categories", "Job Description", "location", "apply", "refer"];

  const initialValues = {
    header: jobPositions.header ? jobPositions.header : headerTemplate
  };

  const gridTemplateColumns = { gridTemplateColumns: `repeat(${initialValues.header.length - 2}, 1fr) repeat(2, 70px)` };

  const renderHeadline = () =>
    initialValues.header.map((h) => {
      let value;

      switch (h.toLowerCase()) {
        case "title":
          value = 'Job Position';
          break;
        case "profile":
          value = 'Category'
          break;
        default:
          value = h;
          break
      }

      return (
          <span key={h} className="mr-2">
            {value}
          </span>
      )
    });

  const doSearching = (value) => {
    const search = value?.search ?? searchValue;
    const categories = value?.categories ?? categoriesValue;
    const locations = value?.locations ?? locationsValue;
    const modes = value?.modes ?? modesValue;
    console.log(jobsPerPage)
    dispatch(fetchJobPositions({ access_token }, jobsPerPage, 1, search?.toLowerCase(), categories, locations, modes));
  };

  const removeSearchTerm = () => {
    setSearchValue("");
    dispatch(setJobsPage(1));
    dispatch(fetchJobPositions({ access_token }, jobsPerPage, 1, '', categoriesValue, locationsValue, modesValue));
  };

  const handleChangeTerm = e => {
    const value = e.target.value;
    setSearchValue(value);

    debounceFn({
      search: value,
      categories: categoriesValue,
      locations: locationsValue,
      modes: modesValue,
    });
  };

  const debounceFn = useCallback(
      _debounce((e) => {
        doSearching(e)
        // Fetch data from API ...
      }, 500),
      []
  );

  const handleChangeCategory = e => {
    const value = e.target.value;
    setCategoriesValue([...categoriesValue, value]);
  };

  const handleChangeLocation = e => {
    const value = e.target.value;
    setLocationValue([...locationsValue, value]);
  };

  const handleChangeMode = e => {
    const value = e.target.value;
    setModesValue([...modesValue, value]);
  };

  const removeCategoryValue = id => {
    setCategoriesValue(categoriesValue.filter((val) => val !== id));
  }

  const removeLocationValue = location => {
    setLocationValue(locationsValue.filter((val) => val !== location));
  }

  const removeModeValue = mode => {
    setModesValue(modesValue.filter((val) => val !== mode));
  }

  const findLocationById = id => {
    const location = jobLocations.find((loc) => loc.original_id == id);

    return location.name;
  }

  const clearFilters = () => {
    setCategoriesValue([]);
    setLocationValue([]);
    setModesValue('');
  }

  const updateJobsPerPage = (e) => {
    setJobsPerPage(e.target.value);
  }

  const findCategoryNameById = id => {
    /* eslint eqeqeq: 0 */
    const category = jobCategories.find((val) => val.id == id);

    return category.name;
  }

  const handleDoSearching = useCallback((value) => {
    if (hasVal1Changed) {
      return;
    }

    const categories = value?.categories ?? categoriesValue;
    const locations = value?.locations ?? locationsValue;
    const modes = value?.modes ?? modesValue;
    const perPage = value?.jobsPerPage ?? jobsPerPage;

    dispatch(fetchJobPositions({ access_token }, perPage, 1, searchValue?.toLowerCase(), categories, locations, modes));
  }, [access_token, categoriesValue, locationsValue, modesValue, dispatch, searchValue, hasVal1Changed, jobsPerPage]);

  useEffect(() => {
    handleDoSearching({
      categories: categoriesValue,
      locations: locationsValue,
      modes: modesValue,
      jobsPerPage,
    });
  }, [categoriesValue, access_token, locationsValue, modesValue, handleDoSearching, jobsPerPage])

  return (
    <div className="metronic_container">
      <div className={`card card-custom card-stretch mb-2 ${/* isTableFixed ? "metronic_fixed-table" :  */"metronic_not-fixed-table"}`}>
        {/* begin::Header */}
        <div className="metronic_sticky-container">
          <div className={`card-header border-0 metronic_jobs column-container job-select-dropdowns`}>
            <h3 className="card-title font-weight-bolder text-dark col-xs-12 col-lg-1">Jobs</h3>
            {
              (
                !!categoriesValue.length || !!locationsValue.length || !! modesValue.length
              )
              &&
            <span 
              className="col-xs-12 col-lg-1 font-weight-bolder text-primary mt-4"
              style={{height: '41.4px', cursor: 'pointer'}}
              onClick={clearFilters}
            >
              Clear all
            </span>
            }
            {
              jobCategories &&
              jobCategories.length &&
              <div className="col-xs-12 col-lg-2">
                <select
                  className="form-control profile-select-input mb-1"
                  onChange={(e) => handleChangeCategory(e)}
                  style={{height: '41.4px'}}
                  value={categoriesValue.length ? categoriesValue[categoriesValue.length - 1] : ''}
                >
                  <option disabled value="">Category</option>
                    {
                      jobCategories
                          .map((option, i)=>{
                              return (
                                  <option
                                      key={i}
                                      disabled={categoriesValue.includes(option.id.toString())}
                                      value={option.id}
                                  >
                                    {option.name}
                                  </option>
                              )
                    })
                    }
                </select>
                {
                (
                  categoriesValue &&
                  categoriesValue.length > 0
                  )
                   &&
                  categoriesValue.map((category, i) => {
                    return(
                      <Chip
                        key={i}
                        label={findCategoryNameById(category)}
                        variant="outlined"
                        onDelete={() => removeCategoryValue(category)}
                        className="mb-1"
                      >
                      </Chip>
                    )
                  })
                }
              </div>
            }
            {
              jobWorkingModes &&
              jobWorkingModes.length &&
              <div className="col-xs-12 col-lg-2">
                <select 
                  className="form-control profile-select-input mb-1"
                  onChange={(e) => handleChangeMode(e)}
                  value={modesValue.length ? modesValue[modesValue.length - 1] : ''}
                  style={{height: '41.4px'}}
                >
                  <option disabled value="">Model</option>
                  {
                      jobWorkingModes
                          .map((option, i)=>{
                              return (
                                  <option
                                      key={i}
                                      value={option.name}
                                      disabled={modesValue.includes(option.name)}
                                  >
                                    {option.name}
                                  </option>
                              )
                      })
                  }
                </select>
                { (modesValue && modesValue.length > 0) &&
                    modesValue.map((mode, index) => {
                      return(
                          <Chip
                              label={mode}
                              variant="outlined"
                              key={index}
                              onDelete={() => removeModeValue(mode)}
                              className="mb-1"
                          >
                          </Chip>
                      )
                    })
                }
              </div>
            }
            {
              jobLocations &&
              jobLocations.length &&
              <div className="col-xs-12 col-lg-2">
                <select
                  className="form-control profile-select-input mb-1"
                  onChange={(e) => handleChangeLocation(e)}
                  style={{height: '41.4px'}}
                  value={locationsValue.length ? locationsValue[locationsValue.length - 1 ] : ''}
                >
                  <option disabled value="">Location</option>
                  {
                      jobLocations
                          .map((option, i)=>{
                              return (
                                  <option
                                      key={i}
                                      value={option.original_id}
                                      disabled={locationsValue.includes(option.original_id.toString())}
                                  >
                                    {option.name}
                                  </option>
                              )
                      })
                  }
                </select>
                {locationsValue.length > 0 && locationsValue !== 'Choose-origin' &&
                    locationsValue.map((location,index) => {
                      return(
                          <>
                            <Chip
                                label={findLocationById(location)}
                                key={index}
                                variant="outlined"
                                className="mb-1"
                                onDelete={() => removeLocationValue(location)}
                            >
                            </Chip>
                          </>
                      )
                    })
                }
              </div>
            }
            <div className="col-xs-12 col-lg-3">
              <SearchBar doSearching={doSearching} handleChangeTerm={handleChangeTerm} removeSearchTerm={removeSearchTerm} value={searchValue} />
            </div>
          </div>

          {!checkDimension && (
            <div className="metronic_jobs-headline" style={gridTemplateColumns}>
              {renderHeadline()}
            </div>
          )}
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body pt-4">
          <JobsT 
            jobPositions={filteredJobPositions} 
            jobsPerPage={jobsPerPage}
            checkDimension={checkDimension}
            gridTemplateColumns={gridTemplateColumns} 
            searchValue={searchValue}
            headerValues={initialValues.header}
            categoriesValue={categoriesValue}
            locationsValue={locationsValue}
            modesValue={modesValue}
            updateJobsPerPage={updateJobsPerPage}
          />
        </div>
        {/* end::Body */}
      </div>
    </div>
  );
};

const useHasChanged= (val) => {
  const prevVal = usePrevious(val)
  return prevVal !== val
}

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export default Jobs;
