import React, { useMemo } from "react";
import { footerListItems, socialIcons, settingsLabels } from "../../../misc";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import CookieConsent from "react-cookie-consent";
import { NavLink } from "react-router-dom";

export const Footer = () => {
  const uiService = useHtmlClassService();
  const currentDate = new Date();
  const privacyPolicy = settingsLabels[1];

  const renderFooterListItem = () =>
    footerListItems.map(listItem => (
      <a href={listItem.redirect} target="_blank" className="nav-link" key={listItem.label} rel="noopener noreferrer">
        {listItem.label}
      </a>
    ));

  const renderSocialIcon = () =>
    socialIcons.map(socialIcon => (
      <a href={socialIcon.href} target="_blank" rel="noopener noreferrer" className="metronic_social-icon" key={socialIcon.alt}>
        <img src={socialIcon.icon} alt={socialIcon.alt} />
      </a>
    ));

  const layoutProps = useMemo(() => {
    return {
      footerClasses: uiService.getClasses("footer", true),
      footerContainerClasses: uiService.getClasses("footer_container", true)
    };
  }, [uiService]);

  return (
    <div className={`footer bg-white py-4 d-flex flex-lg-column ${layoutProps.footerClasses}`} id="kt_footer">
      <CookieConsent
        style={{color: 'white', backgroundColor: 'rgb(235, 100, 56)'}}
        buttonStyle={{ backgroundColor: 'rgb(224, 224, 224)' }}
      >
        By continuing to browse this website you acknowledge it uses essential and functional cookies. You can read more about how we use cookies in our <NavLink to={privacyPolicy.redirect} style={{ textDecoration: 'underline', color: 'white' }}>Privacy Policy</NavLink>
      </CookieConsent>
      <div
        className={`${layoutProps.footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-between metronic_footer-container`}
      >
        <div className="text-dark order-2 order-md-2 order-lg-1">
          <span className="text-muted font-weight-bold mr-2">&copy; {currentDate.getFullYear()}</span>
          <span className="text-dark-75">NATEK. All rights reserved.</span>
        </div>
        <div className="nav nav-dark order-1 order-md-1 order-lg-2 metronic_footer-nav">{renderFooterListItem()}</div>
        <div className="mt-2 mb-2 order-3 order-md-3 order-lg-3">{renderSocialIcon()}</div>
      </div>
    </div>
  );
};
