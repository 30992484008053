import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { Popover, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    candidateDetailsTooltip: {
        fontSize: '1.2rem',
        padding: theme.spacing(2),
    },
    popoverContent: {
        padding: theme.spacing(2),
    },
}));

export default function PopoverCandidateProfiles() {
    const classes = useStyles();
    const [popoverOpen, setPopoverOpen] = useState(false);

    const emailLink = 'mailto:hrjira@natek.eu';

    const handlePopoverToggle = (event) => {
        setPopoverOpen((prev) => !prev);

        setTimeout(() => {
            setPopoverOpen(false);
        }, 5000);
    };

    const handlePopoverClose = () => {
        setPopoverOpen(false);
    };

    return (
        <div onClick={handlePopoverToggle}>
            <Typography className="menu-text" style={{ color: 'gray' }}>
                Delete Profile
            </Typography>
            <Popover
                open={popoverOpen}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}

                onClose={handlePopoverClose}
                disableScrollLock={true}
            >
                <div
                    className={classes.popoverContent}
                    onMouseEnter={handlePopoverToggle}
                    onMouseLeave={handlePopoverClose}
                >
                    <Typography className={classes.candidateDetailsTooltip}>
                       Please contact HR at {' '} <a href={emailLink}>hrjira@natek.eu</a> for deleting your account.
                    </Typography>
                </div>
            </Popover>
        </div>
    );
}
