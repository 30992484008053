import React, { useEffect, useState } from "react";
import { DialogActions, DialogContent, Dialog, DialogTitle, makeStyles, InputLabel, Grid } from "@material-ui/core";
import CustomButton from "../../../../_metronic/layout/components/common/CustomButton";
import CustomTextField from "../../../../_metronic/layout/components/common/CustomTextField";
import { silverColor } from "../../../../_metronic/layout/_core/MaterialThemeProvider";
import FileField from "./FileField";
import { useSelector } from "react-redux";
import { validateFile } from "../../../helpers";

const ApplicationForm = ({ jobPositionPopover, handleApplyJobP, handleCloseJobPositionPopver, jobPositionObject }) => {
  const styles = useStyles();
  const { user } = useSelector(state => state.auth);
  const [originalCv, setOriginalCv] = useState(null);
  const [fileError, setFileError] = useState("");

  useEffect(() => {
    if(user && user.cv) {
      setOriginalCv(user.cv)
    }
  }, [user])

  const initialValues = {
    title: jobPositionObject.title,
    locations: jobPositionObject.locations?.map((location) => {
      return location.name;
    })?.join(',')
  };

  const toUppercase = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  const renderFields = () =>
    Object.keys(initialValues).map(f => (
      <div key={f}>
        <CustomTextField
          className="mt-4 mb-4"
          label={f === 'title' ? 'Job Position' : toUppercase(f)}
          id={f}
          defaultValue={initialValues[f]}
          color="primary"
          InputProps={{
            readOnly: true
          }}
        />
      </div>
    ));

    const handleUploadFile = (e) => {
      const updatedCv = e.target.files[0];
      if (!updatedCv) return;
      const {isFileValid, fileErr} = validateFile(updatedCv, "cv");
      if (isFileValid) {
        setOriginalCv(updatedCv);
        setFileError("");
      } else {
        setFileError(fileErr);
      }
    };

  
  return (
    <Dialog
      classes={{ paper: styles.paper }}
      onClose={handleCloseJobPositionPopver}
      aria-labelledby="metronic_job-app-dialog"
      open={jobPositionPopover}
      className="metronic_custom-dialog"
    >
      <DialogTitle id="metronic_job-app-dialog" classes={{ root: styles.dialogTitleRoot }}>
        <p className={styles.title}>
          We are happy you’d like to <span className={styles.tagName}>#workIT</span> with us!
        </p>
        <p className={styles.subTitle}>You are going to apply for:</p>
      </DialogTitle>
      <DialogContent>
        <span className="d-flex flex-column">{renderFields()}</span>
        <Grid className={styles.customField}>
          <InputLabel className={styles.customLabel}>ORIGINAL CV</InputLabel>
          <FileField 
            field={{
              parameters: [],
              name: "cv",
              type: "file",
              id: "application-cv",
              required: true,
              requiredLabel: "ORIGINAL CV"
            }} 
            value={originalCv}
            handleUploadFile={handleUploadFile}
            fromApplicationForm={true}
            error={fileError}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <CustomButton 
          onClick={() => handleApplyJobP(originalCv, jobPositionObject.id)}
          disabled={!originalCv}
        >
          Apply
        </CustomButton>
        <CustomButton 
          onClick={handleCloseJobPositionPopver}
        >
          Cancel
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default ApplicationForm;

const useStyles = makeStyles(theme => ({
  paper: {
    width: 500,

    "& div": {
      width: "100%"
    }
  },
  dialogTitleRoot: {
    padding: "16px 24px 0px"
  },
  tagName: {
    color: silverColor
  },
  title: {
    fontSize: 17,
    fontWeight: 700
  },
  subTitle: {
    fontSize: 15,
    fontWeight: 500
  },
  customField: {
    marginTop: 13
  },
  customLabel: {
    transform: "translate(0, 1.5px) scale(0.75);"
  }
}));
