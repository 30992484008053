import React from "react";

const CustomHyperlink = props => (
  <>
    {" "}
    <a target="_blank" className="font-weight-bold metronic_privacy-hyperlink" rel="noopener noreferrer" {...props}>
      {props.children}
    </a>{" "}
  </>
);

export default CustomHyperlink;
