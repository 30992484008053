import React from "react";
import { useSelector } from "react-redux";
import CustomButton from "../../../../_metronic/layout/components/common/CustomButton";
import { NavLink } from "react-router-dom";
import JobApplicationsRow from "./JobApplicationsRow";
import { isLastRow, isMobilePhonePortrait } from "../../../helpers";

/* 
    JobApplicationsT is equal to JobApplicationsTemplate, which contains the whole structure of recent open positions. 
    The template display only 5 latest positions, which is coming from the parent component. 
*/

const RecentJobApplicationsT = ({ checkDimension, homePage }) => {
  const jobApplications = useSelector(state => state.data.jobApplications);

  const initialValues = {
    header: jobApplications.header,
    body: jobApplications.body
  };

  if (!initialValues.body || !initialValues.header) {
    return (
      <div>
        No records
      </div>
    );
  }

  const latestFiveJobApps = initialValues.body.slice(0, 5);
  const haveFiveJobsApps = latestFiveJobApps.length === 5;

  const renderHeadline = () => initialValues.header
    .filter(h => !(homePage && h.toLowerCase() === "profile"))
    .map(h => <span key={h}>{h}</span>);

  const renderBody = () => {
    return latestFiveJobApps.map((application, i) => (
      <JobApplicationsRow isLastRow={isLastRow(i, latestFiveJobApps.length)} checkDimension={checkDimension} application={application} key={i} homePage={homePage} />
    ));
  }

  const moreButton = () => (
    <div className="d-flex justify-content-center mt-5">
      <NavLink to="/job-applications" style={{width: isMobilePhonePortrait() ? "100%" : "auto" }}>
        <CustomButton fullWidth={isMobilePhonePortrait() ? true : false}>More</CustomButton>
      </NavLink>
    </div>
  );

  if (!checkDimension) {
    return (
      <div>
        <div className={`metronic_recent-job-applications-headline${homePage ? "-home" : ""}`}>{renderHeadline()}</div>
        <div className="metronic_table-body-container">
          {/* Don't remove the div, because it serves for preserve!  */}
          <div>{renderBody()}</div>

          {haveFiveJobsApps && moreButton()}
        </div>
      </div>
    );
  }

  return (
    <div>
      {renderBody()}

      {haveFiveJobsApps && moreButton()}
    </div>
  );
};

export default RecentJobApplicationsT;
