import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setJobPosition } from "../../../../redux/modules/data/dataActions";
import CustomButton from "../../../../_metronic/layout/components/common/CustomButton";
import ApplicationForm from "./ApplicationForm";
import ReferPopover from "./ReferPopover";
import JobDescriptionLink from "./JobDescriptionLink";
import { freshUserData } from "../../../../redux/modules/auth/authActions";
import { apiCall, isLastRow, jobApplicationApiCall } from "../../../helpers";
import { fetchJobApplications, fetchJobPositions } from "../../../../redux/modules/data/dataActions";
import { useParams } from "react-router";
import JobsPagination from "./JobsPagination";
import { setJobPositionFailureNotification } from "../../../../redux/modules/notifications/notificationsActions";


/* 
    JobsT is equal to JobsTemplate, which contains the whole structure of recent open positions. 
    The template display only 5 latest positions, which is coming from the parent component. 
*/

const JobsT = ({ checkDimension, jobsPerPage, jobPositions, gridTemplateColumns, searchValue, headerValues, categoriesValue, locationsValue, modesValue, updateJobsPerPage }) => {
  const dispatch = useDispatch();
  const jobPositionObject = useSelector(state => state.data.jobPosition);
  const { access_token } = useSelector(state => state.auth);
  const [jobPositionPopover, setJobPositionPopover] = useState(false);
  const { jobsPage } = useSelector(state => state.data);
  const [referPopover, setReferPopover] = useState(false);
  const [appliedJobs, setAppliedJobs] = useState([]);

  useEffect(() => {
    setAppliedJobs(jobPositions ? jobPositions.filter(job => job.applied).map(job => job.id) : []);
  }, [jobPositions]);

  const initialValues = {
    body: jobPositions
  };

  const renderValues = (data, type) => {
    const columns = [...headerValues];
    //remove refer and apply from columns in order to map the correct values
    columns.splice(columns.length - 2, 2);

    return type === "desktop"
      ? columns.map((column, i) => {
          let value;
          switch (column.toLowerCase()) {
            case "job description":
              return <JobDescriptionLink key={data.url} url={data.url} />;
            case "profile":
              value = data.job_categories;
              return (
                <span key={i}>
                  {value.map((category, index) => (
                      <span className="display-block" key={index}>{category.name}</span>
                  ))}
                </span>
              );
            case "ref id":
              value = data.reference_id;
              return <span key={i}>{value}</span>;
            case "title":
              value = data['title'];
              return <span key={i}>{value}</span>;
            case "locations":
              value = data['locations'];
              return (
                  <span key={i}>
                  {value.map((location, index) => (
                      <span className="display-block" key={index}>{location.name}</span>
                  ))}
                  </span>
              );
            default:
              return null;
          }
        })
      : columns.map((column, i) => {
          let value;
          switch (column.toLowerCase()) {
            case "job description":
              //value = data.job_description_file_id;
              return (
                <p key={i}>
                  <span className="metronic_capitalize_text">{column}</span>
                  <JobDescriptionLink url={data.url} />
                </p>
              );
            case "profile":
              value = data.job_categories;
              return (
                <p key={i}>
                  <span className="metronic_capitalize_text">Category</span>
                  <span>
                    {value.map((category, index) => (
                        <span className="display-block font-weight-normal" key={index}>{category.name}</span>
                    ))}
                  </span>
                </p>
              );
            case "ref id":
              value = data.reference_id;
              return (
                  <p key={i}>
                    <span className="metronic_capitalize_text">{column}</span>
                    <span>{value}</span>
                  </p>
              );
            case "title":
              value = data['title'];
              return (
                  <p key={i}>
                    <span className="metronic_capitalize_text">Job Position</span>
                    <span className="display-block">{value}</span>
                  </p>
              );
            case "locations":
              value = data['locations']
              return (
                  <p key={i}>
                    <span className="metronic_capitalize_text">{column}</span>
                    <span>
                      {value.map((value, index) => (
                          <span className="display-block font-weight-normal" key={index}>{value.name}</span>
                      ))}
                    </span>
                  </p>
              );
            default:
              return null;
          }
        });
  };
  const renderData = () =>
    initialValues.body.map(p => (
      <div key={p.id} className="metronic_jobs-body" style={gridTemplateColumns}>
        {renderValues(p, "desktop")}
        <CustomButton color="secondary" disabled={appliedJobs.includes(p.id)} onClick={() => dispatchJobPosition(p)}>
          <i className="fas fa-plus-circle metronic_apply-icon"></i>
        </CustomButton>
        <CustomButton color="secondary" onClick={() => handleReferPopover(p)}>
          <i className="fas fa-sync-alt"></i>
        </CustomButton>
      </div>
    ));

  const renderDataBelowDimension = () =>
    initialValues.body.map((p, i) => (
      <div key={p.id} className="metronic_table-body-below-dimension">
        {renderValues(p, "mobile")}
        <div className="pb-2 pt-2" onClick={!appliedJobs.includes(p.id) ? () => dispatchJobPosition(p) : null}>
          <span>Apply</span>
          <CustomButton color="secondary" disabled={appliedJobs.includes(p.id)}>
            <i className="fas fa-plus-circle"></i>
          </CustomButton>
        </div>
        <div
          className={`pb-2 pt-2 ${isLastRow(i, initialValues.body.length) ? "" : "below-dimension-last-child"}`}
          onClick={() => handleReferPopover(p)}
        >
          <span>Refer</span>
          <CustomButton color="secondary">
            <i className="fas fa-sync-alt"></i>
          </CustomButton>
        </div>
      </div>
    ));

  const dispatchJobPosition = positionObj => {
    dispatch(setJobPosition(positionObj));
    setJobPositionPopover(true);
  };

  const getJobInfo = useCallback(async id => {
    const response = await apiCall(`${process.env.REACT_APP_API_URL}/jobs/${id}?active`, "GET", access_token);
    if (response.success) {
      const job = response.data;
      if (job) {
        dispatchJobPosition(job);
      }
    } else {
      dispatch(setJobPositionFailureNotification(response.error));
    }
    // eslint-disable-next-line
  }, []);

  //check for job id in the params in order to load the page with apply popup open
  const { jobId } = useParams();

  useEffect(() => {
    if (jobId) {
      /* const jobFromParamsArr = jobPositions ? jobPositions.filter((job) => job.id === Number(jobId)) : [];
      const jobFromParam = jobFromParamsArr.length > 0 ? jobFromParamsArr[0] : null; */
      //get job by id and set it for the popup
      getJobInfo(jobId);
    }
  }, [jobId, getJobInfo]);

  if (!initialValues.body || (initialValues.body && initialValues.body.length === 0)) {
    return <div>No results found</div>;
  }

  const handleCloseJobPositionPopver = () => setJobPositionPopover(false);

  const handleApplyJobP = async (updatedCv, jobId) => {
    const applyJobformData = new FormData();
    applyJobformData.append("position_id", jobPositionObject.id);

    if (updatedCv && updatedCv instanceof File) {
      applyJobformData.append("cv", updatedCv);
    }

    const url = `${process.env.REACT_APP_API_URL}/jobs/${jobPositionObject.id}/apply`;
    const response = await jobApplicationApiCall(url, applyJobformData, access_token, dispatch);
    if (response) {
      setJobPositionPopover(false);
      setTimeout(() => {
        dispatch(freshUserData(access_token));
        dispatch(fetchJobPositions({ access_token }, jobsPerPage, jobsPage));
        dispatch(fetchJobApplications({ access_token }, jobsPerPage));
      }, 1000);
      //force update of disabled status of the apply button
      const updatedAppliedJobs = [...appliedJobs];
      updatedAppliedJobs.push(jobId);
      setAppliedJobs(updatedAppliedJobs);
    }
  };

  const handleReferPopover = p => {
    dispatch(setJobPosition(p));
    setReferPopover(true);
  };
  const handleCloseReferPopover = () => setReferPopover(false);

  if (!checkDimension) {
    return (
      <div>
        {jobPositionPopover && jobPositionObject && (
          <ApplicationForm
            handleApplyJobP={handleApplyJobP}
            jobPositionPopover={jobPositionPopover}
            jobPositionObject={jobPositionObject}
            handleCloseJobPositionPopver={handleCloseJobPositionPopver}
          />
        )}

        {referPopover && jobPositionObject && (
          <ReferPopover isOpened={referPopover} jobId={jobPositionObject.id} handleCloseReferPopover={handleCloseReferPopover} />
        )}

        <div className="metronic_table-body-container">
          {/* Don't remove the div, because it serves for preserve!  */}
          <div>{renderData()}</div>
        </div>
        <JobsPagination
          searchValue={searchValue}
          categoriesValue={categoriesValue} 
          locationsValue={locationsValue}
          modesValue={modesValue}
          jobsPerPage={jobsPerPage}
          updateJobsPerPage={updateJobsPerPage}
        />
      </div>
    );
  }

  return (
    <div>
      {jobPositionObject && (
        <ApplicationForm
          handleApplyJobP={handleApplyJobP}
          jobPositionPopover={jobPositionPopover}
          jobPositionObject={jobPositionObject}
          handleCloseJobPositionPopver={handleCloseJobPositionPopver}
        />
      )}

      {referPopover && jobPositionObject && (
        <ReferPopover isOpened={referPopover} jobId={jobPositionObject.id} handleCloseReferPopover={handleCloseReferPopover} />
      )}

      {renderDataBelowDimension()}
      <JobsPagination 
        searchValue={searchValue}
        categoriesValue={categoriesValue} 
        locationsValue={locationsValue}
        modesValue={modesValue}
      />
    </div>
  );
};

export default JobsT;
