import React from "react";
import { DialogActions, DialogContent, DialogContentText, Dialog, DialogTitle } from "@material-ui/core";
import CustomButton from "../../../../_metronic/layout/components/common/CustomButton";
import { settingsLabels } from "../../../../_metronic/misc";
import CustomHyperlink from "../../../../_metronic/layout/components/common/CustomHyperlink";
import { NavLink } from "react-router-dom";

const PrivacyPopover = ({ setOpenPrivacyDialog, openPrivacyDialog, handleContinue }) => {
  const privacyPolicy = settingsLabels[1];

  return (
    <Dialog
      onClose={() => setOpenPrivacyDialog(true)}
      aria-labelledby="metronic_privacy-dialog"
      open={openPrivacyDialog}
      className="metronic_custom-dialog"
    >
      <DialogTitle id="metronic_privacy-dialog">Privacy Notice</DialogTitle>
      <DialogContent>
        <DialogContentText>
          NATEK collects and stores information about you for the purpose of recruitment processes. Give us your GDPR consent by clicking the button
          below. By using MyProfile you accept our&nbsp;
          <NavLink to={privacyPolicy.redirect} style={{ textDecoration: 'underline' }}>Privacy policy</NavLink>. <br /> To request a deletion of your profile write
          us on
          <CustomHyperlink href="mailto:DataProtectionOffice@natek.eu">DataProtectionOffice@natek.eu</CustomHyperlink>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={() => handleContinue()}>Accept</CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default PrivacyPopover;
