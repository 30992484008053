import React from "react";
import { Button, makeStyles } from "@material-ui/core";

const CustomButton = ({ fromAppl, notRootButton, color, variant, classNames, fullWidth, disabled, children, ...rest }) => {
  const styles = useStyles({ notRootButton });

  return (
    <Button
      {...rest}
      classes={{ root: styles.rootButton }}
      color={color ? color : "primary"}
      variant={variant ? variant : "contained"}
      className={`btn btn-sm ${classNames} font-weight-bold mr-2 py-2 px-3 px-xxl-5 my-1`}
      fullWidth={fullWidth ? true : false}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};

export default CustomButton;

const useStyles = makeStyles(() => ({
  rootButton: {
    margin: ({notRootButton}) => (notRootButton ? "0px 5px 1rem 0px !important" : "0px 5px 0px 0px !important"),
    position: "static",

    "& span": {
      fontSize: 11,
      fontWeight: "400 !important",
      color: "white",
      fontFamily: "Lato !important"
    },

    "& a": {
      fontSize: 11,
      fontWeight: "400 !important",
      color: "white",
      fontFamily: "Lato, Helvetica !importan"
    },

    "&:hover span": {
      color: "white"
    },

    "&:hover a": {
      color: "white"
    }
  }
}));
