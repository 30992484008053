import React from "react";
import useCheckDimension from "../../../../_metronic/customHooks/useCheckDimension";
import RecentJobPositionsT from "./RecentJobPositionsT";

const RecentJobPositions = ({ handleApplicationFormOpened }) => {
  const checkDimension = useCheckDimension(1200);

  return (
    <div className="metronic_container metronic_latest-jobs">
      <div className="card card-custom card-stretch">
        {/* begin::Header */}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">Jobs</h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className="card-body pt-4">
          <RecentJobPositionsT checkDimension={checkDimension} handleApplicationFormOpened={handleApplicationFormOpened} />
        </div>
        {/* end::Body */}
      </div>
    </div>
  );
};

export default RecentJobPositions;
