import { SUCCESSFUL_APPLICATION_TEXT } from "../../../app/constants";
import { 
  SET_SUCCESS_JOB_POSITION_NOTIFY, 
  SET_FAILURE_JOB_POSITION_NOTIFY,
  CLOSE_JOB_POSITION_NOTIFY,
  SET_CUSTOM_NOTIFICATION_TEXT,
  SET_CUSTOM_NOTIFICATION_TYPE,
  SET_DIALOG_NOTIFICATION_TEXT
} from "./notificationsActions";

const initialState = {
  jobPositionNotification: false,
  jobPositionNotificationType: null,
  customNotification: null,
  customNotificationType: "success",
  dialogNotification: null
};

const notificationsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SUCCESS_JOB_POSITION_NOTIFY:
      return {
        ...state,
        //jobPositionNotification: payload ? payload : "We are happy to receive your application. Enjoy your recruitment process and #growITwithus!",
        jobPositionNotification: SUCCESSFUL_APPLICATION_TEXT,
        jobPositionNotificationType: "success"
      };
    case SET_FAILURE_JOB_POSITION_NOTIFY:
      return {
        ...state,
        jobPositionNotification: payload ? payload : "We have an error, please, apply correctly your job title!",
        jobPositionNotificationType: "error"
      };
    case CLOSE_JOB_POSITION_NOTIFY:
      return {
        ...state,
        jobPositionNotification: false,
        jobPositionNotificationType: null
      };
    case SET_CUSTOM_NOTIFICATION_TEXT:
      return {
        ...state,
        customNotification: payload
      };
    case SET_CUSTOM_NOTIFICATION_TYPE:
      return {
        ...state,
        customNotificationType: payload
      };
    case SET_DIALOG_NOTIFICATION_TEXT:
      return {
        ...state,
        dialogNotification: payload
      };
    default:
      return {...state};
  }
};

export default notificationsReducer;
