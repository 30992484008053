import React from "react";
import {Chip, makeStyles} from "@material-ui/core";

const SelectField = ({
    field,
    fieldId,
    selectFields,
    setSelectFields,
    value,
    checkForRequiredSelectFields,
    readonly
}) => {
    const styles = useStyles();
    const { label, parameters, options, required } = field;
    const { multiple } = parameters;

    const optionValues = options.map((option)=>option.value);
    if (optionValues.indexOf('select') === -1) {
        options.unshift({label: 'Select an option', value: 'select', selected: false})
    }

    const handleDelete = (i) => {
        //remove option from field value
        const updatedSelectFields = {...selectFields};
        const currentValues = updatedSelectFields[fieldId] ? updatedSelectFields[fieldId] : [];
        currentValues.splice(i, 1);
        updatedSelectFields[fieldId] = currentValues;
        setSelectFields(updatedSelectFields);
        checkForRequiredSelectFields(updatedSelectFields);
    }

    const onSelectChange = (e) => {
        // add option to field value
        const updatedSelectFields = {...selectFields};
        if (multiple) {
            const currentValues = updatedSelectFields[fieldId] ? updatedSelectFields[fieldId] : [];
            if (!currentValues.includes(Number(e.target.value))) {
                currentValues.push(Number(e.target.value));
                updatedSelectFields[fieldId] = e.target.value === 'select' ? [] : currentValues;
            }
        } else {
            updatedSelectFields[fieldId] = e.target.value === 'select' ? null : Number(e.target.value);
        }
        setSelectFields(updatedSelectFields);
        checkForRequiredSelectFields(updatedSelectFields);
    }

  return (
        <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
                {label}
                {required && (<span className="required-identification"> *</span>)}
            </label>
            <div className="col-lg-9 col-xl-6 required-field-wrapper">
                <select 
                    name={label} 
                    id={label} 
                    className={`form-control form-control-lg profile-select-input`}
                    value={multiple && value && value.length > 0 ? value[0] : value}
                    onChange={onSelectChange}
                    disabled={readonly}
                    //multiple={multiple}
                    /* {...formik.getFieldProps(fieldId)} */
                >
                    {options
                        //.unshift({label: 'Select option', value: 'select', selected: false})
                        .map((option, i)=>{
                            return (
                                <option key={i} value={option.value}>{`${option.label.replace(/ /g, '\u00a0')}`}</option>
                            )
                    })}
                </select>
                {required && (!value || (Array.isArray(value) && value.length === 0)) ? <div className="invalid-feedback display-block">{`${label} is required`}</div> : null}
                {!readonly &&
                <div>
                    {multiple && value && value.length > 0 && value.map((val, i) => {
                        const fullValue = options.filter((option) =>option.value === val)[0];
                        return (
                            <Chip 
                                key={i}
                                className={styles.chip} 
                                label={fullValue.label}
                                variant="outlined" 
                                onDelete={() => handleDelete(i)}
                            />
                        )
                    })}
                </div>
                }
            </div>
        </div>
    )
};

export default SelectField;

const useStyles = makeStyles(theme => ({
    chip: {
      marginTop: 10,
      marginRight: 10
    },
  }));
  