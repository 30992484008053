import React from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

const orangeColor = "#eb6438";
const whiteColor = "#fff";
const blueColor = "#0073e9";
const silverColor = "#6c7293";
const greenColor = "#1BC5BD";
const whiteSmokeColor = "#F3F6F9";
const lightGrayColor = "#d3d3d3";
const darkGrayColor = "#555";
const bckColor = "#EBEBEB";

const theme = createMuiTheme(
  /**
   * @see https://material-ui.com/customization/themes/#theme-configuration-variables
   */
  {
    // direction: "rtl",
    typography: {
      fontFamily: ["Lato"].join(",")
    },

    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        main: "#eb6438",
        light: "#fff"
        // dark: will be calculated from palette.primary.main,
        // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
      },
      secondary: {
        // light: will be calculated from palette.primary.main,
        main: "##F3F6F9"

        // dark: will be calculated from palette.primary.main,
        // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
      },
      error: {
        // light: will be calculated from palette.primary.main,
        main: "#eb6438"
        // dark: will be calculated from palette.primary.main,
        // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
      }
    },

    /**
     * @see https://material-ui.com/customization/globals/#default-props
     */
    props: {
      // Name of the component ⚛️
      MuiButtonBase: {
        // The properties to apply
        disableRipple: false // No more ripple, on the whole application 💣!
      },

      // Set default elevation to 1 for popovers.
      MuiPopover: {
        elevation: 1
      }
    },
    overrides: {
      // Style sheet name ⚛️
      MuiAlert: {
        // Name of the rule
        message: {
          fontSize: '1.4em',
        },
      },
    },
  }
);

export { bckColor, whiteColor, orangeColor, blueColor, greenColor, silverColor, darkGrayColor, lightGrayColor, whiteSmokeColor };

export function MaterialThemeProvider(props) {
  const { children } = props;

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
