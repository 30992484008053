import React from "react";
// import PdfFileImage from "./PdfFileImage";
import JobDescriptionLink from "./JobDescriptionLink";

function JobApplicationsRow({
    application,
    homePage,
    checkDimension,
    isLastRow
}) {
    if (!checkDimension) {
        return (
            <div className={`metronic_job-applications-body${homePage ? "-home" : ""}`}>

                <span>{application.position_title}</span>
                {application.categories && !homePage && (
                <span>
                    {application.categories.map((category, index) => (
                        <span key={index}>{category.name}</span>
                    ))}
                </span>
                )}
                <span>{application.position_reference_id}</span>
                <span>
                    {application.position_locations.map((location, index) => (
                        <span className="display-block" key={index}>{location}</span>
                    ))}
                </span>
                <JobDescriptionLink url={application.url} />
                <span>{application.updated_at.split(' ')[0]}</span>
                <span>{application.status}</span>
            </div>
        )
    }

    return (
        <div className="metronic_table-body-below-dimension">
            <p>
                <span>Job Position</span>
                <span>{application.position_title}</span>
            </p>

            <p>
            <span>Category</span>
            <span>
                {application.categories.map((category, index) => (
                <span className="display-block font-weight-normal" key={index}>{category.name}</span>
                ))}
            </span>
            </p>

            <p>
            <span>REF ID</span>
            <span>{application.position_reference_id}</span>
            </p>

            <p>
            <span>Location</span>
            <span>
                {application.position_locations.map((location, index) => (
                    <span className="display-block font-weight-normal" key={index}>{location}</span>
                ))}
            </span>
            </p>

            <p>
            <span>Job Description</span>
            <JobDescriptionLink url={application.url} />
            </p>

            <p>
            <span>Last Update</span>
            <span>{application.updated_at.split(' ')[0]}</span>
            </p>

            <p className={`${isLastRow ? "" : "below-dimension-last-child"}`}>
            <span>Status</span>
            <span>{application.status}</span>
            </p>
        </div>
    )
}

export default JobApplicationsRow;