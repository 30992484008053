import React, { useState, useEffect } from "react";
import UserProfilePage from "../modules/UserProfile/UserProfilePage";
import { SocialNews } from "../modules/UserProfile/components/SocialNews";
import RecentJobPositions from "../modules/UserProfile/components/RecentJobPositions";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import JobApplications from "../modules/UserProfile/components/JobApplications";
import PrivacyPopover from "../modules/UserProfile/components/PrivacyPopover";
import CustomSnackbar from "../modules/UserProfile/components/CustomSnackbar";
import { closeJobPositionNotification } from "../../redux/modules/notifications/notificationsActions";
import { setGDPR } from "../../redux/modules/gdpr/gdprActions";
import { timeToClose } from "../../_metronic/misc";
import { useParams } from "react-router";
import DeleteProfileSection from "../modules/UserProfile/components/DeleteProfileSection";
import { updateUserFlag } from "../../redux/modules/auth/authActions";
import { setJobLastId } from "../../redux/modules/data/dataActions";

const HomePage = ({ history }) => {
  const dispatch = useDispatch();
  const { hasGDPR } = useSelector(state => state.gdpr);
  const [openPrivacyDialog, setOpenPrivacyDialog] = useState(false);
  const [applicationFormOpened, setApplicationFormOpened] = useState(false);
  const { jobPositionNotification, jobPositionNotificationType } = useSelector(state => state.notifications);
  const { user, access_token } = useSelector(state => state.auth);
  const { jobLastId } = useSelector(state => state.data);

  const { deleteHash } = useParams();
  
  const handleContinue = async () => {
    //update user flag
    const body = {
      first_login_confirmed: true
    };
    const response = await dispatch(updateUserFlag({ body, access_token }));
    if (response.first_login_confirmed) {
      setOpenPrivacyDialog(false);
      !hasGDPR && dispatch(setGDPR());
    }
  };

  const handleClose = () => dispatch(closeJobPositionNotification());

  const handleApplicationFormOpened = () => setApplicationFormOpened(true)

  useEffect(() => {
    if (jobLastId) {
      setApplicationFormOpened(true);
      setJobLastId(null);
    } 
  }, [jobLastId]);

  useEffect(() => {
    if(user && user.hasOwnProperty('first_login_confirmed') && !user.first_login_confirmed) {
        setOpenPrivacyDialog(true);
    }
  }, [user, applicationFormOpened]);

  return (
    <>
      {deleteHash && <DeleteProfileSection />}

      {openPrivacyDialog && (
        <PrivacyPopover 
          setOpenPrivacyDialog={setOpenPrivacyDialog} 
          openPrivacyDialog={openPrivacyDialog} 
          handleContinue={handleContinue} 
        />
      )}

      {jobPositionNotification && typeof jobPositionNotification === "string" && (
        <CustomSnackbar
          handleClose={handleClose}
          severenity={jobPositionNotificationType}
          autoHideDuration={timeToClose}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
          open={true}
        >
          {jobPositionNotification}
        </CustomSnackbar>
      )}

      <section className="metronic_homepage-container">
        <UserProfilePage />
        <SocialNews />
        <JobApplications homePage={true} />
        <RecentJobPositions handleApplicationFormOpened={handleApplicationFormOpened} />
      </section>
    </>
  );
};

export default withRouter(HomePage);
