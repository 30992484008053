import React from "react";
import { DialogActions, /* DialogContent,  */Dialog, DialogTitle, makeStyles } from "@material-ui/core";
import CustomButton from "./CustomButton";

const ConfirmPopover = ({ isOpened, handleClosePopover, title, handleConfirm }) => {
  const styles = useStyles();

  return (
    <Dialog
      classes={{ paper: styles.paper }}
      onClose={handleClosePopover}
      aria-labelledby="metronic_refer-dialog"
      open={isOpened}
      className="metronic_custom-dialog"
    >
      <DialogTitle id="metronic_refer-dialog" classes={{ root: styles.dialogTitleRoot }}>
        <p className={styles.title}>{title}</p>
      </DialogTitle>
      {/* <DialogContent>
        <p>
          Refer a friend when writing us on <CustomHyperlink href="mailto:referral@natek.eu">referral@natek.eu</CustomHyperlink>!
        </p>
      </DialogContent> */}
      <DialogActions>
        <CustomButton onClick={handleConfirm}>Confirm</CustomButton>
        <CustomButton onClick={handleClosePopover}>Cancel</CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmPopover;

const useStyles = makeStyles(theme => ({
  paper: {
    width: 500,

    "& div": {
      width: "100%"
    }
  },
  dialogTitleRoot: {
    padding: "16px 24px 0px"
  },
  title: {
    fontSize: 17,
    fontWeight: 700
  },
  customField: {
    marginTop: 33
  },
}));