import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import { Grid } from '@material-ui/core';
import { useSelector, useDispatch } from "react-redux";
import { fetchJobApplications, setJobAppsPage } from '../../../../redux/modules/data/dataActions';
import { isMobilePhonePortrait } from '../../../helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 20,
    paddingBottom: 20,
    minWidth: 260,
    ['@media (max-width:300px)']: { // eslint-disable-line no-useless-computed-key
      marginLeft: '-20px'
    }
  },
}));

function JobAppsPagination() {
  const classes = useStyles();
  const { access_token } = useSelector(state => state.auth);
  const { lastJobAppsPage, jobAppsPage } = useSelector(state => state.data);
  const dispatch = useDispatch();

  const onPageChange = (e, newPage) => {
    dispatch(setJobAppsPage(newPage));
    dispatch(fetchJobApplications({ access_token }, 10, newPage));
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [jobAppsPage])

  return (
    <Grid container justify='center' className={classes.root}>
      <Pagination size={isMobilePhonePortrait() ? "small" : "medium"} page={jobAppsPage} count={lastJobAppsPage} color="primary" onChange={onPageChange} />
    </Grid>
  );
}

export default JobAppsPagination;