import {
  FAILED_REGISTER_USER,
  FULFILLED_REGISTER_USER,
  PENDING_REGISTER_USER,
  PENDING_VERIFY_USER,
  FULFILLED_VERIFY_USER,
  FAILED_VERIFY_USER,
  SET_USER,
  REMOVE_FAILED_NOTIFICATION,
  REMOVE_PASSWORD_CHANGED_MESSAGE,
  PENDING_LOGIN_USER,
  FULFILLED_LOGIN_USER,
  FAILED_LOGIN_USER,
  PENDING_MICROSOFT_REDIRECT,
  FULFILLED_MICROSOFT_REDIRECT,
  FAILED_MICROSOFT_REDIRECT,
  PENDING_REGISTER_MS_USER_REDIRECT,
  FULFILLED_REGISTER_MS_USER_REDIRECT,
  FAILED_REGISTER_MS_USER_REDIRECT,
  PENDING_GET_NEW_TOKENS,
  FULFILLED_GET_NEW_TOKENS,
  FAILED_GET_NEW_TOKENS,
  PENDING_LOGOUT,
  FULFILLED_LOGOUT,
  //FAILED_LOGOUT,
  PENDING_FORGOTTEN_PASSWORD,
  FULFILLED_FORGOTTEN_PASSWORD,
  FAILED_FORGOTTEN_PASSWORD,
  PENDING_VALIDATE_TOKEN_RESET_PASSWORD,
  FULFILLED_VALIDATE_TOKEN_RESET_PASSWORD,
  SET_TOKEN_VALIDATION_ERROR,
  PENDING_RESET_PASSWORD,
  FULFILLED_RESET_PASSWORD,
  FAILED_RESET_PASSWORD,
  PENDING_CHANGE_PASSWORD,
  FULFILLED_CHANGE_PASSWORD,
  FAILED_CHANGE_PASSWORD,
  PENDING_FRESH_USER_DATA,
  FULFILLED_FRESH_USER_DATA,
  FAILED_FRESH_USER_DATA,
  SET_REDIRECT_PATH,
  RESET_FORGOTTEN_PASSWORD_DATA,
  REMOVE_REGISTRATION_EMAIL_SENT_MESSAGE,
  REMOVE_EMAIL_VERIFIED_MESSAGE,
  PENDING_UPDATE_USER_FLAG,
  FULFILLED_UPDATE_USER_FLAG,
  FAILED_UPDATE_USER_FLAG
} from "./authActions";

const userAuth = (state, payload, status) => {
  const todayTime = new Date().getTime();
  const total_expires_in = todayTime + payload.expires_at;
  const twoDaysInSeconds = 172800;
  const targetDate = todayTime + twoDaysInSeconds;

  // Note: We're using twoDaysInSeconds, because we want to avoid different timezones, before checking if token is expired or not!
  const isAccessTokenExpired = total_expires_in <= targetDate;

  return {
    ...state,
    total_expires_in,
    status,
    isUserActive: true,
    isAccessTokenExpired,
    access_token: payload.access_token,
    refresh_token: payload.refresh_token,
    user: payload,
    from: payload.from
  };
};

const microsoftUserRedirect = (state, payload, status) => {
  window.location = payload.redirectLink;
  return {
    ...state
  }
}

const setNewTokens = (state, payload, status) => {
  const todayTime = new Date().getTime();
  const total_expires_in = todayTime + payload.expires_in;
  const twoDaysInSeconds = 172800;
  const targetDate = todayTime + twoDaysInSeconds;

  // Note: We're using twoDaysInSeconds, because we want to avoid different timezones, before checking if token is expired or not!
  const isAccessTokenExpired = total_expires_in <= targetDate;

  return {
    ...state,
    status,
    total_expires_in,
    isUserActive: true,
    isAccessTokenExpired,
    hasNewTokens: true,
    user: { ...state.user, ...payload }
  };
};

const hasError = (state, payload, status) => ({ ...state, status, hasErrorMessage: payload });

const setRegistrationSuccessful = (state, status) => ({ ...state, status, registrationEmailSent: true });

const setVerificationSuccessful = (state, status) => ({ ...state, status, emailVerified: true });

const isRequestPending = (state, status) => ({ ...state, status });

const initialState = {
  status: null,
  total_expires_in: null,
  isUserActive: false,
  isAccessTokenExpired: false,
  user: null,
  hasErrorMessage: false,
  hasNewTokens: false,
  emailForgottenPassword: null,
  validateTokenResetPassword: false,
  resetPassword: false,
  access_token: null,
  refresh_token: null,
  registrationEmailSent: false,
  emailVerified: false,
  passwordChangeSuccessMessage: null,
  redirectPath: null,
  tokenValidationError: null
};

const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case PENDING_LOGIN_USER:
      return isRequestPending(state, PENDING_LOGIN_USER);
    case FULFILLED_LOGIN_USER:
      return userAuth(state, payload, FULFILLED_LOGIN_USER);
    case FAILED_LOGIN_USER:
      return hasError(initialState, payload, FAILED_LOGIN_USER);
    case PENDING_MICROSOFT_REDIRECT:
      return isRequestPending(state, PENDING_MICROSOFT_REDIRECT);
    case FULFILLED_MICROSOFT_REDIRECT:
      return microsoftUserRedirect(state, payload, FULFILLED_MICROSOFT_REDIRECT);
    case FAILED_MICROSOFT_REDIRECT:
      return hasError(initialState, payload, FAILED_MICROSOFT_REDIRECT);
    case SET_USER:
      return { ...state, user: payload };
    case PENDING_REGISTER_USER:
      return isRequestPending(state, PENDING_REGISTER_USER);
    case FULFILLED_REGISTER_USER:
      //don't log user, show registration confirmation message instead
      return setRegistrationSuccessful(state, FULFILLED_REGISTER_USER);
    case FULFILLED_VERIFY_USER:
      return setVerificationSuccessful(state, FULFILLED_VERIFY_USER);
    case FAILED_REGISTER_USER:
      return hasError(initialState, payload, FAILED_REGISTER_USER);
    case FAILED_VERIFY_USER:
      return hasError(initialState, payload, FAILED_VERIFY_USER);
    case PENDING_REGISTER_MS_USER_REDIRECT:
      return isRequestPending(state, PENDING_REGISTER_MS_USER_REDIRECT);
    case FULFILLED_REGISTER_MS_USER_REDIRECT:
      //don't log user, show registration confirmation message instead
      return setRegistrationSuccessful(state, FULFILLED_REGISTER_USER);
    case FAILED_REGISTER_MS_USER_REDIRECT:
      return {};
    case PENDING_VERIFY_USER:
      return isRequestPending(state, PENDING_VERIFY_USER);
    case PENDING_UPDATE_USER_FLAG:
      return isRequestPending(state, PENDING_UPDATE_USER_FLAG);
    case FULFILLED_UPDATE_USER_FLAG:
      return { ...state, status: FULFILLED_UPDATE_USER_FLAG, user: { ...state.user, first_login_confirmed: 1 } }; 
    case FAILED_UPDATE_USER_FLAG:
      return hasError(initialState, payload, FAILED_UPDATE_USER_FLAG);
    case PENDING_GET_NEW_TOKENS:
      return isRequestPending(state, PENDING_GET_NEW_TOKENS);
    case FULFILLED_GET_NEW_TOKENS:
      return setNewTokens(state, payload, FULFILLED_GET_NEW_TOKENS);
    case FAILED_GET_NEW_TOKENS:
      return hasError(initialState, payload, FAILED_GET_NEW_TOKENS);
    case PENDING_FORGOTTEN_PASSWORD:
      return isRequestPending(state, PENDING_FORGOTTEN_PASSWORD);
    case FULFILLED_FORGOTTEN_PASSWORD:
      return { ...state, emailForgottenPassword: payload, status: FULFILLED_FORGOTTEN_PASSWORD };
    case FAILED_FORGOTTEN_PASSWORD:
      return hasError(initialState, payload, FAILED_FORGOTTEN_PASSWORD);
    case PENDING_VALIDATE_TOKEN_RESET_PASSWORD:
      return isRequestPending(state, PENDING_VALIDATE_TOKEN_RESET_PASSWORD);
    case FULFILLED_VALIDATE_TOKEN_RESET_PASSWORD:
      return { ...state, status: FULFILLED_VALIDATE_TOKEN_RESET_PASSWORD, validateTokenResetPassword: payload };
    case SET_TOKEN_VALIDATION_ERROR:
      return { ...state, tokenValidationError: payload ? "This URL is invalid." : null };
    case PENDING_RESET_PASSWORD:
      return isRequestPending(state, PENDING_RESET_PASSWORD);
    case FULFILLED_RESET_PASSWORD:
      return { ...initialState, resetPassword: payload, status: FULFILLED_RESET_PASSWORD };
    case FAILED_RESET_PASSWORD:
      return hasError(initialState, payload, FAILED_RESET_PASSWORD);
    case PENDING_CHANGE_PASSWORD:
      return isRequestPending(state, PENDING_CHANGE_PASSWORD);
    case FULFILLED_CHANGE_PASSWORD:
      return { ...state, user: { ...state.user, password: payload.password }, passwordChangeSuccessMessage: payload.message };
    case FAILED_CHANGE_PASSWORD:
      return hasError(initialState, payload, FAILED_CHANGE_PASSWORD);
    case PENDING_FRESH_USER_DATA:
      return isRequestPending(state, PENDING_FRESH_USER_DATA);
    case FULFILLED_FRESH_USER_DATA:
      return { ...state, status: FULFILLED_FRESH_USER_DATA, user: payload.data };
    case FAILED_FRESH_USER_DATA:
      return hasError(initialState, payload, FAILED_FRESH_USER_DATA);
    case PENDING_LOGOUT:
      return { ...initialState, status: PENDING_LOGOUT };
    case FULFILLED_LOGOUT:
      return { ...initialState, status: FULFILLED_LOGOUT };
    case REMOVE_FAILED_NOTIFICATION:
      return { ...state, status: "", hasErrorMessage: false };
    case REMOVE_PASSWORD_CHANGED_MESSAGE:
      return { ...state, passwordChangeSuccessMessage: null };
    case RESET_FORGOTTEN_PASSWORD_DATA:
      return { ...state, emailForgottenPassword: null };
    case SET_REDIRECT_PATH:
      return { ...state, redirectPath: payload };
    case REMOVE_REGISTRATION_EMAIL_SENT_MESSAGE:
      return { ...state, registrationEmailSent: false };
    case REMOVE_EMAIL_VERIFIED_MESSAGE: 
      return { ...state, emailVerified: false };
    default:
      return state;
  }
};

export default authReducer;
