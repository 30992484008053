import _ from "lodash";
import {
  FAILED_JOB_APPS,
  FAILED_JOB_POSITIONS,
  FAILED_PROFILE_SCHEMA,
  FAILED_JOB_POSITION,
  FULFILLED_JOB_APPS,
  FULFILLED_JOB_POSITIONS,
  FULFILLED_PROFILE_SCHEMA,
  FULFILLED_JOB_POSITION,
  PENDING_JOB_APPS,
  PENDING_JOB_POSITIONS,
  PENDING_PROFILE_SCHEMA,
  PENDING_JOB_POSITION,
  FAILED_JOB_CATEGORIES,
  FAILED_JOB_LOCATIONS,
  FAILED_JOB_MODES,
  FULFILLED_JOB_CATEGORIES,
  FULFILLED_JOB_MODES,
  FULFILLED_JOB_LOCATIONS,
  PENDING_JOB_CATEGORIES,
  PENDING_JOB_LOCATIONS,
  PENDING_JOB_MODES,
  SET_JOB_APPS,
  SET_JOB_POSITION,
  SET_JOBS_PAGE,
  SET_JOB_APPS_PAGE,
  SET_JOB_LAST_ID,
  SET_JOB_CATEGORIES,
  SET_JOB_LOCATIONS,
  SET_JOB_WORKING_MODES,
  FULFILLED_LATEST_POSTS,
} from "./dataActions";

const initialState = {
  recentJobPositions: [],
  jobPosition: [],
  jobApplications: [],
  jobPositions: [],
  jobCategories: [],
  jobLocations: [],
  jobWorkingModes: [],
  profileSchema: [],
  status: null,
  hasErrorMessage: false,
  lastJobsPage: 10,
  jobsPage: 1,
  lastJobAppsPage: 10,
  jobAppsPage: 1,
  jobLastId: null
};

const isRequestPending = (state, status) => ({ ...state, status });

const hasError = (state, payload, status) => ({ ...state, status, hasErrorMessage: payload });

const setJobPositions = (state, payload, status) => {
  const data = payload.data;
  const lastJobsPage = payload.last_page;
  const jobsPage = payload.current_page;
  const dataKeys = data[0] ? Object.keys(data[0]) : [];

  if (data.length) {
    // specify position in array in order to have correct header listing order
    let labels = [];
    dataKeys.forEach(v => {
      switch (v.toLowerCase()) {
        case "title":
          labels[0] = "Title";
          break;
        case "categories":
          labels[1] = "Profile";
          break;
        case "reference_id":
          labels[2] = "REF ID";
          break;
        case "locations":
          labels[3] = "Locations";
          break;
        case "job_description_file_id":
          labels[4] = "Job Description";
          break;
        default:
      }
    });



    const object = { header: [...labels, "apply", "refer"], body: data };

    return { ...state, lastJobsPage, jobsPage, jobPositions: object, hasErrorMessage: false, status };
  } else {
    return { ...state, lastJobsPage, jobsPage, jobPositions: [], hasErrorMessage: false, status };
  }
};

const setProfileSchema = (state, payload, status) => {
  const data = payload.data;

  if (data.length) {
    
    return { ...state, profileSchema: data, hasErrorMessage: false, status };
  } else {
    return { ...state, profileSchema: [], hasErrorMessage: false, status };
  }
};

const setJobPosition = (state, payload, status) => {
  const data = payload.data;

  if (data) {
    return { ...state, jobPosition: data, hasErrorMessage: false, status };
  } else {
    return { ...state, jobPosition: [], hasErrorMessage: false, status };
  }
}

const setJobCategories = (state, payload, status) => {
  const data = payload;

  if (data.length) {
    return { ...state, jobCategories: data, hasErrorMessage: false, status };
  } else {
    return { ...state, jobCategories: [], hasErrorMessage: false, status };
  }
};

const setJobLocations = (state, payload, status) => {
  const data = payload;

  if (data.length) {
    return { ...state, jobLocations: data, hasErrorMessage: false, status };
  } else {
    return { ...state, jobLocations: [], hasErrorMessage: false, status };
  }
};


const setJobWorkingModes = (state, payload, status) => {
  const data = payload;

  if (data.length) {
    return { ...state, jobWorkingModes: data, hasErrorMessage: false, status };
  } else {
    return { ...state, jobWorkingModes: [], hasErrorMessage: false, status };
  }
};

const setJobApps = (state, payload, status) => {
  const data = payload.data;
  const lastJobAppsPage = payload.last_page;
  const jobAppsPage = payload.current_page;

  if (data.length) {
    const dataKeys = Object.keys(data[0]);

    const title = "position_title";
    const categories = "categories";
    const referenceId = "position_reference_id";
    const updatedAt = "updated_at";
    const statusProfile = "status";
    const jobDescPath = "job_description_file_id";
    const location = "position_location";

    let labels = [];
    dataKeys.forEach(v => {
      switch (v.toLowerCase()) {
        case title:
          labels[0] = "Job position";
          break;
        case categories:
          labels[1] = "Profile";
          break;
        case referenceId:
          labels[2] = "REF ID";
          break;
        case location:
          labels[3] = "Locations";
          break;
        case jobDescPath:
          labels[4] = "Job Description";
          break;
        case updatedAt:
          labels[5] = "Last update";
          break;
        case statusProfile:
          labels[6] = "Status";
          break
        default:
      }
    });

    const object = { header: [..._.compact(labels)], body: data };

    return { ...state, lastJobAppsPage, jobAppsPage, jobApplications: object, hasErrorMessage: false, status };
  } else {
    return { ...state, lastJobAppsPage, jobAppsPage, jobApplications: [], hasErrorMessage: false, status };
  }
};

const setLatestPosts = (state, payload) => {
  return {
    ...state,
    latestPosts: payload?.length ? payload : []
  };
};

const dataReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_JOB_POSITION:
      return { ...state, jobPosition: payload };
    case SET_JOBS_PAGE:
      return { ...state, jobsPage: payload };
    case SET_JOB_APPS_PAGE:
      return { ...state, jobAppsPage: payload };
    case SET_JOB_APPS:
      return { ...state, jobApplications: payload };
    case SET_JOB_LAST_ID:
      return { ...state, jobLastId: payload };
    case SET_JOB_CATEGORIES:
      return { ...state, jobCategories: payload };
    case SET_JOB_LOCATIONS:
      return { ...state, jobLocations: payload };  
    case SET_JOB_WORKING_MODES:
      return { ...state, jobWorkingModes: payload };  
    case FAILED_JOB_APPS:
      return hasError(state, payload, FAILED_JOB_APPS);
    case PENDING_JOB_POSITIONS:
      return isRequestPending(state, PENDING_JOB_POSITIONS);
    case FULFILLED_JOB_POSITIONS:
      return setJobPositions(state, payload, FULFILLED_JOB_POSITIONS);
    case FAILED_JOB_POSITIONS:
      return hasError(state, payload, FAILED_JOB_POSITIONS);
    case PENDING_JOB_POSITION:
      return isRequestPending(state, PENDING_JOB_POSITION);
    case FULFILLED_JOB_POSITION:
      return setJobPosition(state, payload, FULFILLED_JOB_POSITION);
    case FAILED_JOB_POSITION:
      return hasError(state, payload, FAILED_JOB_POSITION);
    case PENDING_JOB_APPS:
      return isRequestPending(state, PENDING_JOB_APPS);
    case FULFILLED_JOB_APPS:
      return setJobApps(state, payload, FULFILLED_JOB_APPS);
    /* case FAILED_JOB_APPS:
      return hasError(state, payload, FAILED_JOB_APPS); */
    case PENDING_PROFILE_SCHEMA:
      return isRequestPending(state, PENDING_PROFILE_SCHEMA);
    case FULFILLED_PROFILE_SCHEMA:
      return setProfileSchema(state, payload, FULFILLED_PROFILE_SCHEMA);
    case FAILED_PROFILE_SCHEMA:
      return hasError(state, payload, FAILED_PROFILE_SCHEMA);
    case PENDING_JOB_CATEGORIES:
      return isRequestPending(state, PENDING_JOB_CATEGORIES);
    case FULFILLED_JOB_CATEGORIES:
      return setJobCategories(state, payload, FULFILLED_JOB_CATEGORIES);
    case FAILED_JOB_CATEGORIES:
      return hasError(state, payload, FAILED_JOB_CATEGORIES);
    case PENDING_JOB_LOCATIONS:
      return isRequestPending(state, PENDING_JOB_LOCATIONS);
    case FULFILLED_JOB_LOCATIONS:
      return setJobLocations(state, payload, FULFILLED_JOB_LOCATIONS);
    case FAILED_JOB_LOCATIONS:
      return hasError(state, payload, FAILED_JOB_LOCATIONS);
    case PENDING_JOB_MODES:
      return isRequestPending(state, PENDING_JOB_MODES);
    case FULFILLED_JOB_MODES:
      return setJobWorkingModes(state, payload, FULFILLED_JOB_MODES);
    case FAILED_JOB_MODES:
      return hasError(state, payload, FAILED_JOB_MODES);
    case FULFILLED_LATEST_POSTS:
      return setLatestPosts(state, payload);
  default:
      return state;
  }
};

export default dataReducer;
