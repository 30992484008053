import FbSvg from "./_images/fb.svg";
import InstaSvg from "./_images/insta.svg";
import LiSvg from "./_images/li.svg";
import TwitterSvg from "./_images/twitter.svg";
import YtSvg from "./_images/yt.svg";
import PdfIcon from "./_images/pdfIcon.png";

const capitalizeFirstLetter = string => string[0].toUpperCase() + string.slice(1);

const timeToClose = 5000;

// const recentJobPositions = {
//   header: ["Title", "Category", "Location", "Job Description", "Apply", "Refer"],
//   body: [
//     { title: "MDM Consultant", category: "Software", id: "12", location: "Poland", jobDesc: PdfIcon },
//     { title: "Technical Analyst for digital banking platform", category: "Software", id: "XX3194", location: "OTHER", jobDesc: PdfIcon },
//     { title: "System Engineer/NSX Specialist", category: "Hardware", id: "123", location: "Bulgaria > Sofia", jobDesc: PdfIcon },
//     { title: "Python Developer/Data Modeler", category: "Software", id: "1234", location: "Bulgaria > Sofia", jobDesc: PdfIcon },
//     { title: "Back-end Developer", category: "Hardware", id: "12512", location: "Czech Republic > Prague", jobDesc: PdfIcon },
//     { title: "React developer", client: "SoftwareL", id: "132", location: "", jobDesc: PdfIcon }
//   ]
// };

const jobs = {
  header: ["Title", "Category", "Location", "Job Description", "Apply", "Refer"]
  // body: [
  //   { id: 1, title: "react", category: "it", location: "EUROPE > BULGARIA", jobDesc: PdfIcon },
  //   { id: 2, title: "react", category: "development", location: "EUROPE > BULGARIA", jobDesc: PdfIcon },
  //   { id: 3, title: "angular", category: "IT > DEVELOPMENT", location: "EUROPE > BULGARIA", jobDesc: PdfIcon },
  //   { id: 4, title: "Back end", category: "IT > DEVELOPMENT", location: "EUROPE > BULGARIA", jobDesc: PdfIcon },
  //   { id: 5, title: "Back end", category: "IT > DEVELOPMENT", location: "EUROPE > BULGARIA", jobDesc: PdfIcon },
  //   { id: 10, title: "Back end", category: "IT > DEVELOPMENT", location: "EUROPE > BULGARIA", jobDesc: PdfIcon },
  //   { id: 6, title: "Back end", category: "IT > DEVELOPMENT", location: "EUROPE > BULGARIA", jobDesc: PdfIcon },
  //   { id: 7, title: "Back end", category: "IT > DEVELOPMENT", location: "EUROPE > BULGARIA", jobDesc: PdfIcon },
  //   { id: 8, title: "Back end", category: "IT > DEVELOPMENT", location: "EUROPE > BULGARIA", jobDesc: PdfIcon }
  // ]
};

const jobApplications = {
  header: ["Job Position", "Profile", "REF ID", "Last update", "Status", "Job Description"],
  body: [
    { position: "React dev", profile: "profile", id: "BG3004", lastUpdate: "2021-01-20", status: "In Mission", jobDesc: PdfIcon },
    { position: "Senior React dev", profile: "profile", id: "BG30014", lastUpdate: "2021-01-20", status: "In Mission", jobDesc: PdfIcon },
    { position: "Kotlin dev", profile: "profile", id: "BG13004", lastUpdate: "2021-01-20", status: "In Mission", jobDesc: PdfIcon },
    { position: "Swift dev", profile: "profile", id: "BG300431", lastUpdate: "2021-01-20", status: "In Mission", jobDesc: PdfIcon },
    { position: "React Native dev", profile: "profile", id: "BG341004", lastUpdate: "2021-01-20", status: "In Mission", jobDesc: PdfIcon }
  ]
};

// const menuSubLabels = [
//   { label: "CV", className: "/cv", redirect: "/cv" },
//   { label: "EOL / SCO", className: "/eol-sco", redirect: "/eol-sco" }
// ];

const menuSubLabels = [];

const menuLabels = [
  { className: "/my-profile", label: "My Profile", redirect: "/profile" },
  { className: "/job-applications", label: "Job Applications", redirect: "/job-applications" },
  { className: "/jobs", label: "Jobs", redirect: "/jobs" },
  //{ className: "/offer-letter", label: "Offer Letter", redirect: "/offer-letter" }
  { className: "/cv", label: "CV", redirect: "/cv" }
];

const settingsLabels = [
  { label: "Change password", className: "/change-password", redirect: "/change-password" },
  { label: "Privacy", className: "/privacy", redirect: "/privacy" },
  { label: "Delete profile", className: "/delete-profile" },
];

const footerListItems = [
  { label: "Contact", redirect: "https://natek.eu/contact/" },
  { label: "Privacy policy", redirect: "https://natek.eu/privacy-policy/" },
  { label: "Environment policy", redirect: "https://natek.eu/environmental-policy/" },
  { label: "Quality policy", redirect: "https://natek.eu/quality-policy/" }
];

const socialIcons = [
  { icon: FbSvg, href: "https://www.facebook.com/NATEKworkITwithUS", alt: "Natek Facebook" },
  { icon: InstaSvg, href: "https://www.instagram.com/natek.eu/", alt: "Natek Instagram" },
  { icon: YtSvg, href: "https://www.youtube.com/channel/UCpCt0wPA0DOadwgTfReZ25w", alt: "Natek Youtube" },
  { icon: TwitterSvg, href: "https://twitter.com/natek_eu", alt: "Natek Twitter" },
  { icon: LiSvg, href: "https://www.linkedin.com/company/natek/mycompany/", alt: "Natek Linkedin" }
];

export {
  capitalizeFirstLetter,
  timeToClose,
  socialIcons,
  menuSubLabels,
  menuLabels,
  settingsLabels,
  footerListItems,
  // recentJobPositions,
  jobs,
  jobApplications
};
