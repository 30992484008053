import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Link, Redirect } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import CustomButton from "../../../../_metronic/layout/components/common/CustomButton";
import ReCAPTCHA from "react-google-recaptcha";
import { registerUser, removeFailedNotification, removeRegistrationEmailSentMessage } from "../../../../redux/modules/auth/authActions";
import { timeToClose } from "../../../../_metronic/misc";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import CustomSnackbar from "../../UserProfile/components/CustomSnackbar";
import { emailRegex, validateFile } from "../../../helpers";
import FileField from "../../UserProfile/components/FileField";

const initialValues = {
  first_name: "",
  family_name: "",
  email: "",
  password: "",
  changepassword: "",
  acceptTerms: true
};

const Registration = ({ intl }) => {
  const [loading, setLoading] = useState(false);
  const [isNewsletterChecked, setIsNewsletterChecked] = useState(true);
  const [selectedFile, setSelectedFile] = useState();
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [recaptchaAlert, setRecaptchaAlert] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [registrationMessageOpen, setRegistrationMessageOpen] = useState(true);
  const [fileError, setFileError] = useState("");
  const { hasErrorMessage, registrationEmailSent, redirectPath } = useSelector(state => state.auth);

  const handleRegistrationEmailSentClose = () => {
    dispatch(removeRegistrationEmailSentMessage());
    setRegistrationMessageOpen(false);
  }

  const dispatch = useDispatch();

  const RegistrationSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .matches(/^[a-zA-Z-\s]+$/, intl.formatMessage({ id: "AUTH.VALIDATION.SPECIAL_CHARS" }))
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      ),
    family_name: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .matches(/^[a-zA-Z-\s]+$/, intl.formatMessage({ id: "AUTH.VALIDATION.SPECIAL_CHARS" }))
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      ),
    email: Yup.string()
      //.email("Wrong email format")
      .matches(emailRegex, "Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      ),
    password: Yup.string()
      .min(8, "Minimum 8 symbols")
      .max(50, "Maximum 50 symbols")
      .matches(/^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/, intl.formatMessage({ id: "AUTH.VALIDATION.PASSWORD_REGEX" }))
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      ),
    changepassword: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      )
      .when("password", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf([Yup.ref("password")], "Password and Confirm Password didn't match")
      }),
    acceptTerms: Yup.bool().required("You must accept the terms and conditions")
  });

  const changeHandler = e => {
    const file = e.target.files[0];
    if (!file) return;
    const {isFileValid, fileErr} = validateFile(file, "cv");
    if (isFileValid) {
      setSelectedFile(file);
      setFileError("");
    } else {
      setFileError(fileErr);
    }
  };

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const handleRecaptcha = token => setRecaptchaToken(token);

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      setLoading(true);

      //if registration is prompted from external link, get info about it from the redirect link 
      const pathParts = redirectPath ? redirectPath.split("/") : [];
      const entityId = pathParts[2];
      const action = pathParts[3];

      const data = {
        grant_type: "password",
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET_ID,
        username: values.email,
        email: values.email,
        password: values.password,
        password_confirmation: values.password,
        scope: "*",
        first_name: values.first_name,
        family_name: values.family_name,
        gdpr_consent: 1,
        newsletter_consent: isNewsletterChecked ? 1 : 0,
        isNewsletterChecked,
      };

      const formDataPR = new FormData();

      formDataPR.append("grant_type", "password");
      formDataPR.append("client_id", process.env.REACT_APP_CLIENT_ID);
      formDataPR.append("client_secret", process.env.REACT_APP_CLIENT_SECRET_ID);
      formDataPR.append("username", values.email);
      formDataPR.append("email", values.email);
      formDataPR.append("password", values.password);
      formDataPR.append("password_confirmation", values.password);
      formDataPR.append("scope", "*");
      formDataPR.append("first_name", values.first_name);
      formDataPR.append("family_name", values.family_name);
      formDataPR.append("gdpr_consent", 1);
      formDataPR.append("newsletter_consent", isNewsletterChecked ? 1 : 0);
      selectedFile && formDataPR.append("cv", selectedFile);

      if (entityId && !isNaN(entityId)) {
        data.entity_id = entityId;
        formDataPR.append("entity_id", entityId);

        if (action) {
          data.action = action === "apply" ? "job-apply" : action;
          formDataPR.append("action", action === "apply" ? "job-apply" : action);
        }
      }

      if (!recaptchaToken) {
        setRecaptchaAlert(true);
        setLoading(false);
      } else {
        setRecaptchaAlert(false);
        dispatch(registerUser({ formDataPR, data }));
      }
    }
  });

  const handleClose = () => dispatch(removeFailedNotification());

  useEffect(() => {
    let isCancelled = false;

    if (!isCancelled) {
      if (hasErrorMessage) {
        setLoading(true);

        setTimeout(() => {
          handleClose();
          setLoading(false);
        }, timeToClose);
      } else {
        setLoading(false);
      }
    }

    return () => (isCancelled = true);
    // eslint-disable-next-line
  }, [hasErrorMessage]);

  useEffect(() => {
    if (registrationEmailSent) {
      setTimeout(() => {
        setRedirect(true);
        dispatch(removeRegistrationEmailSentMessage());
      }, timeToClose);
    }
  }, [registrationEmailSent, dispatch]);

  if (redirect) {
    return <Redirect to="/auth/login" />
  }

  return (
    <>
      {registrationEmailSent && (
        <CustomSnackbar
          handleClose={handleRegistrationEmailSentClose}
          severenity={"success"}
          autoHideDuration={3500}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
          open={registrationMessageOpen}
        >
          Registration email was sent for confirmation! Please check your mailbox!
        </CustomSnackbar>
      )}
      <div className="login-form login-signin" style={{ display: "block" }}>
        <div className="text-center mb-10 mb-lg-20">
          <h3 className="font-size-h1">
            <FormattedMessage id="AUTH.REGISTER.TITLE" />
          </h3>
          <p className="text-muted font-weight-bold">Enter your details to create your account</p>
        </div>

        {hasErrorMessage && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{hasErrorMessage}</div>
          </div>
        )}

        {recaptchaAlert && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold"> Verifying reCAPTCHA, please try again!</div>
          </div>
        )}

        <form
          id="kt_login_signin_form"
          className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          onSubmit={formik.handleSubmit}
        >
          {/* begin: Alert */}
          {formik.status && (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          )}
          {/* end: Alert */}
          {/* begin: first_name */}
          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder="First name"
              type="text"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses("first_name")}`}
              name="first_name"
              {...formik.getFieldProps("first_name")}
            />
            {formik.touched.first_name && formik.errors.first_name ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.first_name}</div>
              </div>
            ) : null}
          </div>
          {/* end: first_name */}
          {/* begin: family_name */}
          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder="Surname"
              type="text"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses("family_name")}`}
              name="family_name"
              {...formik.getFieldProps("family_name")}
            />
            {formik.touched.family_name && formik.errors.family_name ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.family_name}</div>
              </div>
            ) : null}
          </div>
          {/* end: family_name */}
          {/* begin: Email */}
          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder="Email"
              type="text"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses("email")}`}
              name="email"
              {...formik.getFieldProps("email")}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.email}</div>
              </div>
            ) : null}
          </div>
          {/* end: Email */}
          {/* begin: Password */}
          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder="Password"
              type="password"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses("password")}`}
              name="password"
              {...formik.getFieldProps("password")}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.password}</div>
              </div>
            ) : null}
          </div>
          {/* end: Password */}
          {/* begin: Confirm Password */}
          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder="Confirm Password"
              type="password"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses("changepassword")}`}
              name="changepassword"
              {...formik.getFieldProps("changepassword")}
            />
            {formik.touched.changepassword && formik.errors.changepassword ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.changepassword}</div>
              </div>
            ) : null}
          </div>
          {/* end: Confirm Password */}

          {/* begin: CV */}
          <FileField 
            field={{
              parameters: [],
              name: "cv",
              type: "file",
              id: "registration-cv"
            }} 
            value={selectedFile}
            handleUploadFile={changeHandler}
            error={fileError}
          />
          {/* end: CV */}

          {/* beging: Google Recaptcha */}
          <ReCAPTCHA sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY} onChange={handleRecaptcha} />
          {/* end: Google Recaptcha */}

          {/* begin: Terms & Conditions and Newsletters */}
          <div className="form-group mt-4">
            <FormControlLabel
              control={<Checkbox checked={true} name="checkedB" color="primary" />}
              label="I hereby agree to give my consent about storing my data to NATEK"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isNewsletterChecked}
                  onChange={() => setIsNewsletterChecked(prevState => !prevState)}
                  name="checkedB"
                  color="primary"
                />
              }
              label="I hereby agree to receive newsletters from NATEK."
            />
          </div>
          {/* end: Terms & Conditions and Newsletters */}
          <div className="form-group d-flex flex-wrap flex-center">
            <CustomButton type="submit" disabled={loading} classNames="font-weight-bold px-9 py-4 my-3 mx-4">
              <span className={loading ? "mr-1" : ""}>Submit</span>
              {loading && <span className="mr-4 spinner spinner-white"></span>}
            </CustomButton>

            <Link to="/auth/login">
              <CustomButton type="button" classNames="font-weight-bold px-9 py-4 my-3 mx-4">
                Back
              </CustomButton>
            </Link>
          </div>
        </form>
      </div>
    </>
  );
};

export default injectIntl(Registration);
