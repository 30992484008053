import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import { persistStore } from "redux-persist";
import thunk from "redux-thunk";
import gdpr from "./modules/gdpr/gdprReducer";
import data from "./modules/data/dataReducer";
import auth from "./modules/auth/authReducer";
import notifications from "./modules/notifications/notificationsReducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "gdpr", "data", "notifications"]
};

const reducers = combineReducers({
  auth,
  gdpr,
  data,
  notifications
});

const reducerPersist = persistReducer(persistConfig, reducers);

export const store = createStore(reducerPersist, composeWithDevTools(applyMiddleware(thunk)));

export const persistor = persistStore(store);
