/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Switch, Redirect, useLocation } from "react-router-dom";
import { ContentRoute } from "../../../../_metronic/layout";
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import useCheckDimension from "../../../../_metronic/customHooks/useCheckDimension";
import NatekLogo from "../../../../_metronic/_images/NATEK-logo-transparent.png";
import { socialIcons } from "../../../../_metronic/misc";
import NatekBackground from "../../../../_metronic/_images/NATEK-bck-2.png";
import { removeFailedNotification, setRedirectPath } from "../../../../redux/modules/auth/authActions";
import { setJobLastId } from "../../../../redux/modules/data/dataActions";

export const AuthPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentDate = new Date();

  const isNotLaptop = useCheckDimension(992);
  const isSignUpPage = useLocation().pathname.includes("registration");
  const isLoginPage = useLocation().pathname.includes("login");

  const { jobLastId } = useSelector(state => state.data);

  const footerListItems = [
    { label: "Contact", redirect: "https://natek.eu/contact/" },
    { label: "Privacy policy", redirect: "https://natek.eu/privacy-policy/" },
    { label: "Environment policy", redirect: "https://natek.eu/environmental-policy/" },
    { label: "Quality policy", redirect: "https://natek.eu/quality-policy/" }
  ];

  const renderFooterListItem = () =>
    footerListItems.map(listItem => (
      <a
        href={listItem.redirect}
        target="_blank"
        className={`metronic_list-item opacity-70 mr-3 font-weight-bold text-align-center text-center ${isNotLaptop ? "text-dark" : "text-white"}`}
        key={listItem.label}
        rel="noopener noreferrer"
      >
        {listItem.label}
      </a>
    ));

  const renderSocialIcon = () =>
    socialIcons.map(socialIcon => (
      <a href={socialIcon.href} target="_blank" rel="noopener noreferrer" className="metronic_social-icon" key={socialIcon.alt}>
        <img src={socialIcon.icon} alt={socialIcon.alt} />
      </a>
    ));

  useEffect(() => {
    let isCancelled = false;

    if (location.pathname && location.pathname.includes('verify')) {
      isCancelled = true;
    }

    if (!isCancelled) {
      dispatch(removeFailedNotification());
    }

    return () => (isCancelled = true);
    // eslint-disable-next-line
  }, [location.pathname]);

  useEffect(()=>{
    const from = location.state ? location.state.from : null;
    if (from) {
      const { pathname } = from;
      // Set last job id
      if (pathname && pathname.split('/') && pathname.split('/')[2] && pathname.includes('jobs') && pathname.includes('apply')) {
        const lastJobId = pathname.split('/')[2];
        dispatch(setJobLastId(lastJobId));
      }
      dispatch(setRedirectPath(pathname));
    } else if (jobLastId) {
      const path = `/jobs/${jobLastId}/apply`;
      dispatch(setRedirectPath(path));
      dispatch(setJobLastId(jobLastId));
    } else {
      dispatch(setRedirectPath(null))
    }
    // eslint-disable-next-line
  }, [location.state]);

  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white" id="kt_login">
          {/*begin::Aside*/}
          <div
            className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10 metronic_login-left-side"
            style={{
              backgroundImage: `url(${NatekBackground})`
            }}
          >
            {/*begin: Aside Container*/}
            <div className="d-flex flex-row-fluid flex-column justify-content-between">
              {/* start:: Aside header */}
              <Link to="/auth/login" className="flex-column-auto mt-5 pb-lg-0 pb-10 d-flex justify-content-center">
                <img alt="Logo" className="max-h-70px metronic_login-logo" src={NatekLogo} />
              </Link>
              {/* end:: Aside header */}

              {/* start:: Aside content */}
              <div className="flex-column-fluid d-flex flex-column justify-content-center">
                <h3 className="opacity-70 font-size-h1 mb-5 text-white text-center metronic_auth-title">Welcome to My Profile!</h3>
              </div>
              {/* end:: Aside content */}

              {/* start:: Aside footer for desktop */}
              <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10 metronic_auth-footer">
                <div className="d-flex mb-2">{renderFooterListItem()}</div>

                <div className="opacity-70 font-weight-bold	text-white metronic_auth-copy">&copy; {currentDate.getFullYear()} NATEK. All rights reserved.</div>
                <div className="mt-2 mb-2">{renderSocialIcon()}</div>
              </div>
              {/* end:: Aside footer for desktop */}
            </div>
            {/*end: Aside Container*/}
          </div>
          {/*begin::Aside*/}

          {/*begin::Content*/}
          <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
            {/*begin::Content header*/}

            {isSignUpPage && (
              <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
                <span className="font-weight-bold text-dark-50">You already have an account?</span>
                <Link to="/auth/login" className="font-weight-bold ml-2" id="kt_login_signup">
                  Sign in!
                </Link>
              </div>
            )}

            {isLoginPage && (
              <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
                <span className="font-weight-bold text-dark-50">Don't have an account yet?</span>
                <Link to="/auth/registration" className="font-weight-bold ml-2" id="kt_login_signup">
                  Sign Up!
                </Link>
              </div>
            )}
            {/*end::Content header*/}

            {/* begin::Content body */}
            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
              <Switch>
                <ContentRoute exact path="/auth/login" component={Login} />
                <ContentRoute exact path="/auth/registration" component={Registration} />
                <ContentRoute exact path="/auth/forgotten-password/request" component={ForgotPassword} />
                <ContentRoute exact path="/auth/forgotten-password/reset/token/:token" component={ResetPassword} />
                <ContentRoute exact path="/auth/email/verify/:id/:hash/:expiration/:signature" component={Login} />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5 pb-0 metronic_footer-container">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-2 my-2">&copy; {currentDate.getFullYear()} NATEK. All rights reserved.</div>
              <div className="d-flex order-1 order-sm-1 my-2 metronic_footer-nav">{renderFooterListItem()}</div>
              <div className="mt-2 mb-2 order-3 order-sm-3 my-3">{renderSocialIcon()}</div>
            </div>
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
};
