import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { setJobPosition } from "../../../../redux/modules/data/dataActions";
import CustomButton from "../../../../_metronic/layout/components/common/CustomButton";
import ApplicationForm from "./ApplicationForm";
import ReferPopover from "./ReferPopover";
// import PdfFileImage from "./PdfFileImage";
import JobDescriptionLink from "./JobDescriptionLink";
import { freshUserData } from "../../../../redux/modules/auth/authActions";
import { isLastRow, isMobilePhonePortrait, jobApplicationApiCall } from "../../../helpers";
import { fetchJobApplications, fetchJobPositions } from "../../../../redux/modules/data/dataActions";

/* 
    RecentJobPositions is equal to Jobs, which contains the whole structure of recent open positions. 
    The template display only 5 latest positions, which is coming from the parent component. 
*/

const RecentJobPositionsT = ({ checkDimension, handleApplicationFormOpened }) => {
  const dispatch = useDispatch();
  const jobPositionObject = useSelector(state => state.data.jobPosition);
  const jobPositions = useSelector(state => state.data.jobPositions);
  const { user, access_token } = useSelector(state => state.auth);
  const [jobPositionPopover, setJobPositionPopover] = useState(false);
  const [referPopover, setReferPopover] = useState(false);
  const [appliedJobs, setAppliedJobs] = useState([]);

  useEffect(() => {
    if (jobPositions.body) {
      setAppliedJobs(jobPositions.body.filter(job => job.applied).map(job => job.id));
    }
  }, [jobPositions]);

  const itemsPerPage = 100;

  const initialValues = {
    header: jobPositions.header,
    body: jobPositions.body
  };

  if (!initialValues.body || !initialValues.header) {
    return null;
  }

  const latestFivePositions = initialValues.body.slice(0, 5);
  const haveFivePositions = latestFivePositions.length === 5;

  const gridTemplateColumns = { gridTemplateColumns: `repeat(${initialValues.header.length - 3}, 1fr) repeat(2, 70px)` };

  const renderHeadline = () =>
    initialValues.header
      .filter(h => h.toLowerCase() !== "profile")
      .map((h) => {
          let value = h;

          switch (value.toLowerCase()) {
              case 'title':
                  value = 'Job Position'
                  break;
              default:
                  break
          }

          return (
              <span key={h} className="mr-2">
              {value}
            </span>
          )
      });

  const renderValues = (data, type) => {
    const columns = [...initialValues.header];
    //remove refer and apply from columns in order to map the correct values
    columns.splice(columns.length - 2, 2);

    return type === "desktop"
      ? columns.map((column, i) => {
          let value;
          switch (column?.toLowerCase()) {
            case "job description":
              // value = data.job_description_file_id;
              // return (
              //   <PdfFileImage key={i} fileId={value} />
              // );
              return <JobDescriptionLink key={data.url} url={data.url} />;
            /* case "profile":
            value = data.categories;
            return (
              <span key={i}>
                {value.map(v => (
                  <span key={v}>{v}</span>
                ))}
              </span>
            ); */
            case "ref id":
              value = data.reference_id;
              return <span key={i}>{value}</span>;
            case "title":
              value = data['title'];
              return <span key={i}>{value}</span>;
            case "locations":
              value = data['locations'];
              return <span key={i}>
                  {value.map((location, index) => (
                      <span className="display-block" key={index}>{location.name}</span>
                  ))}
                  </span>
            default:
              return null;
          }
        })
      : columns.map((column, i) => {
          let value;
          switch (column?.toLowerCase()) {
            case "job description":
              value = data.job_description_file_id;
              return (
                <p key={i}>
                  <span className="metronic_capitalize_text">{column}</span>
                  <JobDescriptionLink url={data.url} />
                </p>
              );
            /* case "profile":
            value = data.categories;
            return (
              <p key={i}>
                <span className="metronic_capitalize_text">{column}</span>
                {value.map(v => (
                  <span key={v}>{v}</span>
                ))}
              </p>
            ); */
            case "ref id":
              value = data.reference_id;
              return (
                  <p key={i}>
                    <span className="metronic_capitalize_text">{column}</span>
                    <span>{value}</span>
                  </p>
              );
            case "title":
              value = data['title'];
              return (
                  <p key={i}>
                    <span className="metronic_capitalize_text">Job Position</span>
                    <span>{value}</span>
                  </p>
              );
            case "locations":
              value = data['locations'];
              return (
                  <p key={i}>
                    <span className="metronic_capitalize_text">{column}</span>
                    <span>
                      {value.map((location, index) => (
                          <span className="display-block font-weight-normal" key={index}>{location.name}</span>
                      ))}
                    </span>
                  </p>
              );
            default:
              return null;
          }
        });
  };

  const renderLatestFivePositions = () =>
    latestFivePositions.map(p => (
      <div key={p.id} className="metronic_recent-open-positions-body" style={gridTemplateColumns}>
        {renderValues(p, "desktop")}
        <CustomButton color="secondary" disabled={appliedJobs.includes(p.id)} onClick={() => dispatchJobPosition(p)}>
          <i className="fas fa-plus-circle metronic_apply-icon"></i>
        </CustomButton>
        <CustomButton color="secondary" onClick={() => handleReferPopover(p)}>
          <i className="fas fa-sync-alt"></i>
        </CustomButton>
      </div>
    ));

  const renderLatestFivePositionsBelowDimension = () => {
    return latestFivePositions.map((p, i) => (
      <div key={p.id} className="metronic_table-body-below-dimension">
        {renderValues(p, "mobile")}
        <div className="pb-2 pt-2">
          <span>Apply</span>
          <CustomButton color="secondary" disabled={appliedJobs.includes(p.id)} onClick={() => dispatchJobPosition(p)}>
            <i className="fas fa-plus-circle metronic_apply-icon"></i>
          </CustomButton>
        </div>
        <div className={`pb-2 pt-2 ${isLastRow(i, latestFivePositions.length) ? "" : "below-dimension-last-child"}`}>
          <span>Refer</span>
          <CustomButton color="secondary" onClick={() => handleReferPopover(p)}>
            <i className="fas fa-sync-alt"></i>
          </CustomButton>
        </div>
      </div>
    ));
  };

  const dispatchJobPosition = positionObj => {
    dispatch(setJobPosition(positionObj));
    if (user) {
      setJobPositionPopover(true);
      handleApplicationFormOpened();
    }
  };

  const handleCloseJobPositionPopver = () => setJobPositionPopover(false);

  const handleApplyJobP = async (updatedCv, jobId) => {
    const applyJobformData = new FormData();
    applyJobformData.append("position_id", jobPositionObject.id);

    if (updatedCv && updatedCv instanceof File) {
      applyJobformData.append("cv", updatedCv);
    }
    const url = `${process.env.REACT_APP_API_URL}/jobs/${jobPositionObject.id}/apply`;
    const response = jobApplicationApiCall(url, applyJobformData, access_token, dispatch);
    setJobPositionPopover(false);
    if (response) {
      setTimeout(() => {
        dispatch(freshUserData(access_token));
        dispatch(fetchJobPositions({ access_token }, itemsPerPage));
        dispatch(fetchJobApplications({ access_token }, itemsPerPage));
      }, 1000);
      //force update of disabled status of the apply button
      const updatedAppliedJobs = [...appliedJobs];
      updatedAppliedJobs.push(jobId);
      setAppliedJobs(updatedAppliedJobs);
    }
  };

  const handleReferPopover = p => {
    dispatch(setJobPosition(p));
    setReferPopover(true);
  };
  const handleCloseReferPopover = () => setReferPopover(false);

  const moreButton = () => (
    <div className="d-flex justify-content-center mt-5">
      <NavLink style={{ width: isMobilePhonePortrait() ? "100%" : "auto" }} to="/jobs">
        <CustomButton fromAppl={true} fullWidth={isMobilePhonePortrait() ? true : false}>
          More
        </CustomButton>
      </NavLink>
    </div>
  );

  if (!checkDimension) {
    return (
      <div>
        {jobPositionObject && (
          <ApplicationForm
            handleApplyJobP={handleApplyJobP}
            jobPositionPopover={jobPositionPopover}
            jobPositionObject={jobPositionObject}
            handleCloseJobPositionPopver={handleCloseJobPositionPopver}
          />
        )}

        {jobPositionObject && referPopover && (
          <ReferPopover jobId={jobPositionObject.id} isOpened={referPopover} handleCloseReferPopover={handleCloseReferPopover} />
        )}

        <div className="metronic_recent-open-positions-headline" style={gridTemplateColumns}>
          {renderHeadline()}
        </div>
        <div className="metronic_table-body-container">
          {/* Don't remove the div, because it serves for preserve!  */}
          <div>{renderLatestFivePositions()}</div>
        </div>
        {haveFivePositions && moreButton()}
      </div>
    );
  }

  return (
    <div>
      {jobPositionPopover && jobPositionObject && (
        <ApplicationForm
          handleApplyJobP={handleApplyJobP}
          jobPositionPopover={jobPositionPopover}
          jobPositionObject={jobPositionObject}
          handleCloseJobPositionPopver={handleCloseJobPositionPopver}
        />
      )}

      {jobPositionObject && referPopover && (
        <ReferPopover isOpened={referPopover} jobId={jobPositionObject.id} handleCloseReferPopover={handleCloseReferPopover} />
      )}

      {renderLatestFivePositionsBelowDimension()}

      {haveFivePositions && moreButton(moreButton)}
    </div>
  );
};

export default RecentJobPositionsT;
