import React from "react";
import useCheckDimension from "../../../../_metronic/customHooks/useCheckDimension";
import JobApplicationsT from "./JobApplicationsT";
import RecentJobApplicationsT from "./RecentJobApplicationsT";

const JobApplications = ({
  homePage
}) => {
  const checkDimension = useCheckDimension(992);

  return (
    <div className="metronic_container metronic_job-apps">
      <div className="card card-custom card-stretch">
        {/* begin::Header */}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">Job Applications</h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className="card-body pt-4">
          {homePage ? (
            <RecentJobApplicationsT checkDimension={checkDimension} homePage={homePage} />
          ) : (
            <JobApplicationsT checkDimension={checkDimension} homePage={homePage} />
          )}
        </div>
        {/* end::Body */}
      </div>
    </div>
  );
};

export default JobApplications;
