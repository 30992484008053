import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { MyProfilePage } from "./pages/MyProfilePage";

const HomePage = lazy(() => import("./pages/HomePage"));
const PrivacyPage = lazy(() => import("./pages/PrivacyPage"));
const JobApplicationsPage = lazy(() => import("./pages/JobApplicationsPage"));
const JobsPage = lazy(() => import("./pages/JobsPage"));
const SignaturePage = lazy(() => import("./pages/SignaturePage"));
const ChangePasswordPage = lazy(() => import("../../src/app/modules/UserProfile/ChangePassword"));
const CVPage = lazy(() => import("./pages/CVPage"));
const EOLPage = lazy(() => import("./pages/EOLPage"));

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/privacy" component={PrivacyPage} />
        <Route exact path="/job-applications" component={JobApplicationsPage} />
        <Route exact path="/jobs" component={JobsPage} />
        <Route exact path="/signature" component={SignaturePage} />
        <Route exact path="/change-password" component={ChangePasswordPage} />
        <Route exact path="/cv" render={props => <CVPage key={props.location.key} {...props} />} />
        <Route exact path="/eol-sco" component={EOLPage} />
        <ContentRoute exact path="/profile" component={MyProfilePage} />

        <Redirect to="error" />
      </Switch>
    </Suspense>
  );
}
