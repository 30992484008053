import React/* , { useState } */ from "react";

const InfoField = ({
    formik,
    getInputClasses,
    type,
    label,
    placeholder,
    fieldId,
    icon,
    required,
    readonly
}) => {

    const handleInputChange = (val) => {
        const newValue = fieldId  === "phone" ? val.replaceAll(" ", "") : val;
        formik.setFieldValue(fieldId, newValue);
    }

  return (
        <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
                {label}
                {required && (<span className="required-identification"> *</span>)}
            </label>
            <div className="col-lg-9 col-xl-6">
            {icon ? (
                <div className="input-group input-group-lg input-group-solid">
                    <div className="input-group-prepend profile-icon">
                        <span className="input-group-text">
                            <i className={icon}></i>
                        </span>
                    </div>
                    <input
                        type={type !== "email" ? type : "text"}
                        placeholder={placeholder}
                        className={`form-control form-control-lg form-control-solid ${getInputClasses(fieldId)}`}
                        name={fieldId}
                        required={required}
                        readOnly={readonly}

                        {...formik.getFieldProps(fieldId)}
                        onChange={(e) => handleInputChange(e.target.value)}                
                    />
                </div>
            ) : (
                <input
                    type={type !== "email" ? type : "text"}
                    placeholder={placeholder}
                    className={`form-control form-control-lg form-control-solid ${getInputClasses(fieldId)}`}
                    name={fieldId}
                    required={required}
                    readOnly={readonly}
                    {...formik.getFieldProps(fieldId)}
                    onChange={(e) => handleInputChange(e.target.value)}
                />
            )}
            {formik.touched[fieldId] && formik.errors[fieldId] ? <div className="invalid-feedback display-block">{formik.errors[fieldId]}</div> : null}
            {fieldId === "phone" && (
                <span className="form-text text-muted">We will use your phone only for the recruitment purposes.</span>
            )}
            </div>
        </div>
    )
};

export default InfoField;