import React from "react";
import { useSelector } from "react-redux";
import { isLastRow } from "../../../helpers";
import JobAppsPagination from "./JobApplicationsPagination";
import JobApplicationsRow from "./JobApplicationsRow";

/* 
    JobApplicationsT is equal to JobApplicationsTemplate, which contains the whole structure of recent open positions. 
    The template display only 5 latest positions, which is coming from the parent component. 
*/

const JobApplicationsT = ({ checkDimension, homePage }) => {
  const jobApplications = useSelector(state => state.data.jobApplications);

  const initialValues = {
    header: jobApplications.header,
    body: jobApplications.body
  };

  if (!initialValues.body || !initialValues.header) {
    return (
      <div>
        No records
      </div>
    );
  }

  const renderHeadline = () => initialValues.header
    .filter(h => !(homePage && h.toLowerCase() === "profile"))
    .map(h => <span key={h}>{h.toLowerCase() === 'profile' ? 'Category' : h}</span>);

  const renderBody = () => {
    return initialValues.body.map((application, i) => (
      <JobApplicationsRow isLastRow={isLastRow(i, initialValues.body.length)} checkDimension={checkDimension} application={application} key={i} homePage={homePage} />
    ));
  }

  if (!checkDimension) {
    return (
      <div>
        <div className={`metronic_recent-job-applications-headline${homePage ? "-home" : ""}`}>{renderHeadline()}</div>
        <div className="metronic_table-body-container">
          {/* Don't remove the div, because it serves for preserve!  */}
          <div>{renderBody()}</div>

          <JobAppsPagination />
        </div>
      </div>
    );
  }

  return (
    <div>
      {renderBody()}

      <JobAppsPagination />
    </div>
  );
};

export default JobApplicationsT;
