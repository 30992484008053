import React from "react";
import {
  Route,
  Redirect,
} from "react-router-dom";
import { useSelector } from "react-redux";

function ExternalRoute({ children, ...rest }) {
    const { isAuthorized } = useSelector(({ auth }) => ({
        isAuthorized: auth.user != null
    }));

    return (
      <Route
        {...rest}
        render={({ location }) =>
          isAuthorized ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/auth/login",
                state: { from: location }
              }}
            />
          )
        }
      />
    );
}

export default ExternalRoute;
  