const SET_SUCCESS_JOB_POSITION_NOTIFY = "set-success-job-position-notify";
const SET_FAILURE_JOB_POSITION_NOTIFY = "set-failure-job-position-notify";
const CLOSE_JOB_POSITION_NOTIFY = "close-job-position-notify";
const SET_CUSTOM_NOTIFICATION_TEXT = "set-custom-notification-text";
const SET_CUSTOM_NOTIFICATION_TYPE = "set-custom-notification-type";
const SET_DIALOG_NOTIFICATION_TEXT = "set-dialog-notification-text"

const setJobPositionSuccessNotification = (payload) => ({
  type: SET_SUCCESS_JOB_POSITION_NOTIFY,
  payload
});

const setJobPositionFailureNotification = (payload) => ({
  type: SET_FAILURE_JOB_POSITION_NOTIFY,
  payload
});

const closeJobPositionNotification = () => ({
  type: CLOSE_JOB_POSITION_NOTIFY
});

const setCustomNotificationText = (payload) => ({
  type: SET_CUSTOM_NOTIFICATION_TEXT,
  payload
});

const setDialogNotificationText = (payload) => ({
  type: SET_DIALOG_NOTIFICATION_TEXT,
  payload
});

const setCustomNotificationType = (payload) => ({
  type: SET_CUSTOM_NOTIFICATION_TYPE,
  payload
});

export { 
  setJobPositionSuccessNotification, 
  setJobPositionFailureNotification, 
  closeJobPositionNotification,
  setCustomNotificationText,
  setCustomNotificationType,
  setDialogNotificationText
};

export { 
  SET_SUCCESS_JOB_POSITION_NOTIFY, 
  SET_FAILURE_JOB_POSITION_NOTIFY, 
  CLOSE_JOB_POSITION_NOTIFY,
  SET_CUSTOM_NOTIFICATION_TEXT,
  SET_CUSTOM_NOTIFICATION_TYPE,
  SET_DIALOG_NOTIFICATION_TEXT
};
