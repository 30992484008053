//Async Action Creators
const PENDING_REGISTER_USER = "pending-register-user";
const FULFILLED_REGISTER_USER = "fulfilled-register-user";
const FAILED_REGISTER_USER = "failed-register-user";
//const CLOSE_REGISTRATION_EMAIL_SENT = "close-registration-email-sent";

const PENDING_VERIFY_USER = "pending-verify-user";
const FULFILLED_VERIFY_USER = "fulfilled-verify-user";
const FAILED_VERIFY_USER = "failed-verify-user";

const PENDING_LOGIN_USER = "pending-login-user";
const FULFILLED_LOGIN_USER = "fulfilled-login-user";
const FAILED_LOGIN_USER = "failed-login-user";

const PENDING_MICROSOFT_REDIRECT = "pending-microsoft-redirect";
const FULFILLED_MICROSOFT_REDIRECT = "fulfilled-microsoft-redirect";
const FAILED_MICROSOFT_REDIRECT = "failed-microsoft-redirect";

const PENDING_REGISTER_MS_USER_REDIRECT = "pending-register-microsoft-user-redirect";
const FULFILLED_REGISTER_MS_USER_REDIRECT = "fulfilled-register-microsoft-user-redirect";
const FAILED_REGISTER_MS_USER_REDIRECT = "failed-register-microsoft-user-redirect";

const PENDING_GET_NEW_TOKENS = "pending-get-new-tokens";
const FULFILLED_GET_NEW_TOKENS = "fulfilled-get-new-tokens";
const FAILED_GET_NEW_TOKENS = "failed-get-new-tokens";

const PENDING_LOGOUT = "pending-logout";
const FULFILLED_LOGOUT = "fulfilled-logout";
const FAILED_LOGOUT = "failed-logout";

const PENDING_FORGOTTEN_PASSWORD = "pending-forgotten-password";
const FULFILLED_FORGOTTEN_PASSWORD = "fulfilled-forgotten-password";
const FAILED_FORGOTTEN_PASSWORD = "failed-forgotten-password";

const PENDING_VALIDATE_TOKEN_RESET_PASSWORD = "pending-validate-token-reset-password";
const FULFILLED_VALIDATE_TOKEN_RESET_PASSWORD = "fulfilled-validate-token-reset-password";
const SET_TOKEN_VALIDATION_ERROR = "set-token-validation-error";

const PENDING_RESET_PASSWORD = "pending-reset-pasword";
const FULFILLED_RESET_PASSWORD = "fulfilled-reset-password";
const FAILED_RESET_PASSWORD = "failed-reset-password";

const PENDING_CHANGE_PASSWORD = "pending-change-password";
const FULFILLED_CHANGE_PASSWORD = "fulfilled-change-password";
const FAILED_CHANGE_PASSWORD = "failed-change-password";

const PENDING_FRESH_USER_DATA = "pending-fresh-user-data";
const FULFILLED_FRESH_USER_DATA = "fulfilled-fresh-user-data";
const FAILED_FRESH_USER_DATA = "failed-fresh-user-data";

const PENDING_UPDATE_USER_FLAG = "pending-update-user-flag";
const FULFILLED_UPDATE_USER_FLAG = "fulfilled-update-user-flag";
const FAILED_UPDATE_USER_FLAG = "failed-update-user-flag";

//Sync Action Creators
const SET_USER = "set-user";
const LOGOUT_USER = "logout-user";
const REMOVE_FAILED_NOTIFICATION = "remove-failed-notification";
const REMOVE_PASSWORD_CHANGED_MESSAGE = "remove-password-change-message";
const RESET_FORGOTTEN_PASSWORD_DATA = "reset-forgotten-password-data";
const SET_REDIRECT_PATH = "set-redirect-path";
const REMOVE_REGISTRATION_EMAIL_SENT_MESSAGE = "remove-registration-email-sent";
const REMOVE_EMAIL_VERIFIED_MESSAGE = "remove-email-verified";

//Async Actions
const pendingRegisterUser = () => ({ type: PENDING_REGISTER_USER });

const pendingVerifyUser = () => ({ type: PENDING_VERIFY_USER });

const pendingLoginUser = () => ({ type: PENDING_LOGIN_USER });

const pendingMicrosoftRedirect = () => ({ type: PENDING_MICROSOFT_REDIRECT });

const pendingRegisterMicrosoftUserRedirect = () => ({ type: PENDING_REGISTER_MS_USER_REDIRECT });

const pendingGetNewTokens = () => ({ type: PENDING_GET_NEW_TOKENS });

const pendingUpdateUserFlag = () => ({ type: PENDING_UPDATE_USER_FLAG });

const pendingLogout = () => ({ type: PENDING_LOGOUT });

const pendingForgottenPassword = () => ({ type: PENDING_FORGOTTEN_PASSWORD });

const pendigValidateTokenRP = () => ({ type: PENDING_VALIDATE_TOKEN_RESET_PASSWORD });

const pendingResetPassword = () => ({ type: PENDING_RESET_PASSWORD });

const pendingChangePassword = () => ({ type: PENDING_CHANGE_PASSWORD });

const pendingFreshUserData = () => ({ type: PENDING_FRESH_USER_DATA });

const setRedirectPath = payload => ({
  type: SET_REDIRECT_PATH,
  payload
});

const fulfilledRegisterUser = payload => ({
  type: FULFILLED_REGISTER_USER,
  payload
});

const fulfilledVerifyUser = payload => ({
  type: FULFILLED_VERIFY_USER,
  payload
});

/* const closeRegistrationEmailSent = () => ({
  type: CLOSE_REGISTRATION_EMAIL_SENT
}); */

const fulfilledLoginUser = payload => ({
  type: FULFILLED_LOGIN_USER,
  payload
});

const fulfilledMicrosoftRedirect = payload => ({ 
  type: FULFILLED_MICROSOFT_REDIRECT,
  payload
});

const fulfilledRegisterMicrosoftUserRedirect = payload => ({
  type: FULFILLED_REGISTER_MS_USER_REDIRECT,
  payload
});

const fulfilledGetNewTokens = payload => ({
  type: FULFILLED_GET_NEW_TOKENS,
  payload
});

const fulfilledUpdateUserFlag = payload => ({
  type: FULFILLED_UPDATE_USER_FLAG,
  payload
});

const fulfilledLogout = payload => ({
  type: FULFILLED_LOGOUT,
  payload
});

const fulfilledForgottenPassword = payload => ({
  type: FULFILLED_FORGOTTEN_PASSWORD,
  payload
});

const fulfilledValidateTokenRP = payload => ({
  type: FULFILLED_VALIDATE_TOKEN_RESET_PASSWORD,
  payload
});

const fulfilledResetPassword = payload => ({
  type: FULFILLED_RESET_PASSWORD,
  payload
});

const fulfilledChangePassword = payload => ({
  type: FULFILLED_CHANGE_PASSWORD,
  payload
});

const fulfilledFreshUserData = payload => ({
  type: FULFILLED_FRESH_USER_DATA,
  payload
});

const failedRegisterUser = payload => ({
  type: FAILED_REGISTER_USER,
  payload
});

const failedVerifyUser = payload => ({
  type: FAILED_VERIFY_USER,
  payload
});

const failedLoginUser = payload => ({
  type: FAILED_LOGIN_USER,
  payload
});

const failedMicrosoftRedirect = payload => ({ 
  type: FAILED_MICROSOFT_REDIRECT,
  payload
});

const failedRegisterMicrosoftUserRedirect = payload => ({
  type: FAILED_REGISTER_MS_USER_REDIRECT,
  payload
});

const failedGetNewTokens = payload => ({
  type: FAILED_GET_NEW_TOKENS,
  payload
});

const failedUpdateUserFlag = payload => ({
  type: FAILED_UPDATE_USER_FLAG,
  payload
});

const failedLogout = payload => ({
  type: FAILED_LOGOUT,
  payload
});

const failedForgottenPassword = payload => ({
  type: FAILED_FORGOTTEN_PASSWORD,
  payload
});

const setTokenValidationError = payload => ({
  type: SET_TOKEN_VALIDATION_ERROR,
  payload
});

const failedResetPassword = payload => ({
  type: FAILED_RESET_PASSWORD,
  payload
});

const failedChangePassword = payload => ({
  type: FAILED_CHANGE_PASSWORD,
  payload
});

const failedFreshUserData = payload => ({
  type: FAILED_FRESH_USER_DATA,
  payload
});

// Sync Actions

const setUser = payload => ({
  type: SET_USER,
  payload
});

const removeFailedNotification = () => ({ type: REMOVE_FAILED_NOTIFICATION });

const removePasswordChangedMessage = () => ({ type: REMOVE_PASSWORD_CHANGED_MESSAGE });

const removeRegistrationEmailSentMessage = () => ({ type: REMOVE_REGISTRATION_EMAIL_SENT_MESSAGE });

const removeEmailVerifiedMessage = () => ({ type: REMOVE_EMAIL_VERIFIED_MESSAGE });

const resetForgottenPasswordData = () => ({ type: RESET_FORGOTTEN_PASSWORD_DATA });

// A switch case for building custom bodies and headers for every API call

const requestOptions = (requestBody, requestName, bearerToken) => {
  switch (requestName) {
    case "register":
      return {
        method: "POST",
        headers: {
          "Cache-Control": "no-cache"
        },
        body: requestBody
      };
    case "login-with-microsoft":
      return {
        method: "POST",
        headers: {
          "Cache-Control": "no-cache"
        }
      };
    case "register-with-microsoft":
      return {
        method: "POST",
        headers: {
          "Cache-Control": "no-cache"
        },
        body: JSON.stringify(requestBody)
      }
    case "login":
      return {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache"
        },
        body: JSON.stringify(requestBody)
      };
    case "get-tokens":
    case "update-user-flag":
      return {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearerToken}`,
          "Cache-Control": "no-cache"
        },
        body: JSON.stringify(requestBody)
      };
    case "logout":
      return {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearerToken}`,
          "Cache-Control": "no-cache"
        }
      };
    case "forgotten-password":
      return {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache"
        },
        body: JSON.stringify(requestBody)
      };
    case "change-password":
      return {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearerToken}`,
          "Cache-Control": "no-cache"
        },
        body: JSON.stringify(requestBody)
      };
    //TODO Add reset password case
    case "validate-token-rp":
      return {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache"
        },
        body: JSON.stringify(requestBody)
      };
    case "reset-password":
      return {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache"
        },
        body: JSON.stringify(requestBody)
      };
    case "fresh-user-data":
      return {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearerToken}`,
          "Cache-Control": "no-cache"
        }
      };
    default:
      return {};
  }
};

// This is a template to make API calls easily.

const apiCall = async (
  dispatch,
  pendingAction,
  fulfilledAction,
  failedAction,
  url,
  requestBody,
  requestNewFormDataBody,
  requestName,
  bearerToken
) => {
  try {
    dispatch(pendingAction());
    const apiResponse = await fetch(
      url,
      requestOptions(requestNewFormDataBody ? requestNewFormDataBody : requestBody, requestName, bearerToken && bearerToken)
    );

    const apiResponseJSON = await apiResponse.json();

    const apiData = { ...requestBody, ...apiResponseJSON };

    if (!apiResponse.ok) {
      const payload = apiResponseJSON.data ? apiResponseJSON.data[Object.keys(apiResponseJSON.data)[0]] : apiResponseJSON.message;
      throw new Error(payload);
    } else {
      dispatch(fulfilledAction(apiData));
      return apiData;
    }
  } catch (error) {
    const message = error.message && error.message.includes('fetch') ? "The action cannot be completed." : error.message;
    dispatch(failedAction(message));
  }
};

// Async actions

const verifyUser = ({ endpoint }) => {
  return async dispatch => {
    await apiCall(
      dispatch,
      pendingVerifyUser,
      fulfilledVerifyUser,
      failedVerifyUser,
      `${process.env.REACT_APP_API_URL}/${endpoint}`,
      undefined,
      undefined,
      "verify",
      undefined
    );
  };
};


const registerUser = ({ formDataPR, data }) => {
  return async dispatch => {
    await apiCall(
      dispatch,
      pendingRegisterUser,
      fulfilledRegisterUser,
      failedRegisterUser,
      `${process.env.REACT_APP_API_URL}/register`,
      data,
      formDataPR,
      "register",
      undefined
    );
  };
};

const loginUser = data => {
  return async dispatch => {
    await apiCall(
      dispatch,
      pendingLoginUser,
      fulfilledLoginUser,
      failedLoginUser,
      `${process.env.REACT_APP_API_URL}/login`,
      data,
      undefined,
      "login",
      undefined
    );
  };
};

const loginUserMS = data => {
  return async dispatch => {
    dispatch(fulfilledLoginUser(data))
  };
}

const registerUserMs = data => {
  return async dispatch => {
    await apiCall(
      dispatch,
      pendingRegisterMicrosoftUserRedirect,
      fulfilledRegisterMicrosoftUserRedirect,
      failedRegisterMicrosoftUserRedirect,
      `${process.env.REACT_APP_API_URL}/register-with-microsoft`,
      data,
      null,
      "register-with-microsoft",
      undefined
    );
  };
}

const microsoftLoginPost = data => {
  return async dispatch => {
    await apiCall(
      dispatch,
      pendingMicrosoftRedirect,
      fulfilledMicrosoftRedirect,
      failedMicrosoftRedirect,
      `${process.env.REACT_APP_API_URL}/login-with-microsoft`,
      undefined,
      undefined,
      "login-with-microsoft",
      undefined
    );
  }
};

const microsoftRedirect = data => {
  return async dispatch => {
    await apiCall(
      dispatch,
      pendingMicrosoftRedirect,
      fulfilledMicrosoftRedirect,
      failedMicrosoftRedirect,
      `${process.env.REACT_APP_API_URL}/login-with-microsoft`,
      undefined,
      undefined,
      "login-with-microsoft",
      undefined
    );
  };
};

const getNewTokens = ({ body, access_token }) => {
  return async dispatch => {
    await apiCall(
      dispatch,
      pendingGetNewTokens,
      fulfilledGetNewTokens,
      failedGetNewTokens,
      `${process.env.REACT_APP_API_URL}/refresh-token`,
      body,
      undefined,
      "get-tokens",
      access_token
    );
  };
};

const logoutUser = access_token => {
  return async dispatch => {
    await apiCall(
      dispatch,
      pendingLogout,
      fulfilledLogout,
      failedLogout,
      `${process.env.REACT_APP_API_URL}/logout`,
      undefined,
      undefined,
      "logout",
      access_token
    );
  };
};

const forgottenPassword = data => {
  return async dispatch => {
    await apiCall(
      dispatch,
      pendingForgottenPassword,
      fulfilledForgottenPassword,
      failedForgottenPassword,
      `${process.env.REACT_APP_API_URL}/forgotten-password/request`,
      data,
      undefined,
      "forgotten-password",
      undefined
    );
  };
};

const changePassword = data => {
  const { password, access_token } = data;

  const updatedPassword = {
    password,
    password_confirmation: password
  };

  return async dispatch => {
    await apiCall(
      dispatch,
      pendingChangePassword,
      fulfilledChangePassword,
      failedChangePassword,
      `${process.env.REACT_APP_API_URL}/profile/change-password`,
      updatedPassword,
      undefined,
      "change-password",
      access_token
    );
  };
};

const validateTokenRP = data => {
  return async dispatch => {
    await apiCall(
      dispatch,
      pendigValidateTokenRP,
      fulfilledValidateTokenRP,
      setTokenValidationError,
      `${process.env.REACT_APP_API_URL}/forgotten-password/validate-token`,
      data,
      undefined,
      "validate-token-rp",
      undefined
    );
  };
};

const resetPassword = data => {
  return async dispatch => {
    await apiCall(
      dispatch,
      pendingResetPassword,
      fulfilledResetPassword,
      failedResetPassword,
      `${process.env.REACT_APP_API_URL}/forgotten-password/reset`,
      data,
      undefined,
      "reset-password",
      undefined
    );
  };
};

const freshUserData = access_token => {
  return async dispatch => {
    await apiCall(
      dispatch,
      pendingFreshUserData,
      fulfilledFreshUserData,
      failedFreshUserData,
      `${process.env.REACT_APP_API_URL}/profile`,
      undefined,
      undefined,
      "fresh-user-data",
      access_token
    );
  };
};

const updateUserFlag = ({ body, access_token }) => {
  return async dispatch => {
    const res = await apiCall(
      dispatch,
      pendingUpdateUserFlag,
      fulfilledUpdateUserFlag,
      failedUpdateUserFlag,
      `${process.env.REACT_APP_API_URL}/profile/flag`,
      body,
      undefined,
      "update-user-flag",
      access_token
    );
    return res;
  };
};

export {
  loginUser,
  loginUserMS,
  microsoftLoginPost,
  microsoftRedirect,
  registerUserMs,
  setUser,
  logoutUser,
  verifyUser,
  updateUserFlag,
  registerUser,
  forgottenPassword,
  validateTokenRP,
  resetPassword,
  changePassword,
  getNewTokens,
  removeFailedNotification,
  removePasswordChangedMessage,
  resetForgottenPasswordData,
  freshUserData,
  setRedirectPath,
  removeRegistrationEmailSentMessage,
  removeEmailVerifiedMessage,
  fulfilledLogout,
  failedRegisterUser,
  setTokenValidationError
};

export {
  PENDING_REGISTER_USER,
  FULFILLED_REGISTER_USER,
  FAILED_REGISTER_USER,
  PENDING_VERIFY_USER,
  FULFILLED_VERIFY_USER,
  FAILED_VERIFY_USER,
  PENDING_LOGIN_USER,
  FULFILLED_LOGIN_USER,
  FAILED_LOGIN_USER,
  PENDING_MICROSOFT_REDIRECT,
  FULFILLED_MICROSOFT_REDIRECT,
  FAILED_MICROSOFT_REDIRECT,
  PENDING_REGISTER_MS_USER_REDIRECT,
  FULFILLED_REGISTER_MS_USER_REDIRECT,
  FAILED_REGISTER_MS_USER_REDIRECT,
  FAILED_GET_NEW_TOKENS,
  PENDING_GET_NEW_TOKENS,
  FULFILLED_GET_NEW_TOKENS,
  PENDING_LOGOUT,
  FULFILLED_LOGOUT,
  FAILED_LOGOUT,
  PENDING_FORGOTTEN_PASSWORD,
  FULFILLED_FORGOTTEN_PASSWORD,
  FAILED_FORGOTTEN_PASSWORD,
  PENDING_RESET_PASSWORD,
  FULFILLED_RESET_PASSWORD,
  FAILED_RESET_PASSWORD,
  PENDING_VALIDATE_TOKEN_RESET_PASSWORD,
  FULFILLED_VALIDATE_TOKEN_RESET_PASSWORD,
  SET_TOKEN_VALIDATION_ERROR,
  PENDING_CHANGE_PASSWORD,
  FULFILLED_CHANGE_PASSWORD,
  FAILED_CHANGE_PASSWORD,
  PENDING_FRESH_USER_DATA,
  FULFILLED_FRESH_USER_DATA,
  FAILED_FRESH_USER_DATA,
  SET_USER,
  LOGOUT_USER,
  REMOVE_FAILED_NOTIFICATION,
  REMOVE_PASSWORD_CHANGED_MESSAGE,
  RESET_FORGOTTEN_PASSWORD_DATA,
  SET_REDIRECT_PATH,
  REMOVE_REGISTRATION_EMAIL_SENT_MESSAGE,
  REMOVE_EMAIL_VERIFIED_MESSAGE,
  PENDING_UPDATE_USER_FLAG,
  FULFILLED_UPDATE_USER_FLAG,
  FAILED_UPDATE_USER_FLAG
};
