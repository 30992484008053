import React from "react";
import { makeStyles, Snackbar, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";

const CustomSnackbar = ({ open, handleClose, severenity, autoHideDuration, children, anchorOrigin }) => {
  const styles = useStyles();

  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      action={
        <>
          <IconButton aria-label="close" color="inherit" className={styles.close} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </>
      }
    >
      <Alert onClose={handleClose} severity={severenity}>
        {children}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;

const useStyles = makeStyles(theme => ({
  close: {
    padding: theme.spacing(0.5)
  }
}));
