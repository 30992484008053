/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { socialIcons } from "../../../../_metronic/misc";
import { isMobilePhonePortrait } from "../../../helpers";
import { LatestPostsSlider } from "./LatestPostsSlider";

export const SocialNews = () => {
  const renderSocialIcon = () =>
    socialIcons.map(socialIcon => (
      <a href={socialIcon.href} target="_blank" rel="noopener noreferrer" className="metronic_social-icon" key={socialIcon.alt}>
        <img src={socialIcon.icon} alt={socialIcon.alt} />
      </a>
    ));

  return (
    <div className="metronic_container metronic_news">
      <div className="card card-custom card-stretch">
        {/* begin::Header */}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">News Feed</h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className="card-body pt-4 metronic_social-news-body">
          <LatestPostsSlider/>
          <div className={`d-flex align-items-center ${isMobilePhonePortrait() ? "flex-column m-ato" : "mt-auto mr-auto"}`}>
            <p className="metronic_follow-us">Follow us on:</p>
            <div className="mt-2 mb-2">{renderSocialIcon()}</div>
          </div>
        </div>
        {/* end::Body */}
      </div>
    </div>
  );
};
