import React, { useState, useCallback, useEffect } from 'react';
import { useParams, withRouter } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import CustomSnackbar from './CustomSnackbar';
import { fulfilledLogout } from "../../../../redux/modules/auth/authActions";
import ConfirmPopover from "../../../../_metronic/layout/components/common/ConfirmPopover";

function DeleteProfileSection({
  history
}) {
  const [deleteProfilePopover, setDeleteProfilePopover] = useState(false);
  const [deleteProfileInitiated, setDeleteProfileInitiated] = useState(false);
  const [deleteMessageInfo, setDeleteMessageInfo] = useState(null);
  const dispatch = useDispatch();
  const { access_token } = useSelector(state => state.auth);

  const deleteProfileApiCall = async (url) => {
    let message = {
      text: "Profile successfully deleted!",
      severenity: "success"
    }
    try {
      const apiResponse = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Cache-Control": "no-cache"
        },
      });
  
      const apiResponseJSON = await apiResponse.json();
  
      const payload = apiResponseJSON.data ? apiResponseJSON.data[Object.keys(apiResponseJSON.data)[0]] : apiResponseJSON.message;
      if (!apiResponse.ok) {
        message.text = payload;
        message.severenity = "error"
      } else {
        message.text = apiResponseJSON.message;
        //dispatch user null with set timeout 3.5
        setTimeout(()=> {
          dispatch(fulfilledLogout())
        }, 3500);
      }
      setDeleteMessageInfo(message);
    } catch (error) {
      message.text = error.message;
      message.severenity = "error";
      setDeleteMessageInfo(message);
    }
    setDeleteProfileInitiated(true);
  };
    //check for delete signature in the params in order to continue delete profile process
    const { deleteHash, expires, deleteSignature } = useParams();

    const deleteProfile = useCallback(async () => {
        const deleteUrl = `${process.env.REACT_APP_API_URL}/account/delete/confirm/${deleteHash}?expires=${expires}&signature=${deleteSignature}`
        await deleteProfileApiCall(deleteUrl);
        // eslint-disable-next-line
    }, [expires, deleteHash, deleteSignature])

    useEffect(()=>{
        if(deleteSignature) {
          //open confirmation popup and delete it only after is confirmed
          setDeleteProfilePopover(true);
        }
        // eslint-disable-next-line
    }, [deleteSignature])

    const handleClosePopover = () => {
      setDeleteProfilePopover(false);
      history.push("/");
    };

    const handleConfirm = () => {
      setDeleteProfilePopover(false);
      deleteProfile();
    }

    return (
      <>
        {deleteProfilePopover && (
          <ConfirmPopover 
            isOpened={deleteProfilePopover} 
            handleClosePopover={handleClosePopover}
            handleConfirm={handleConfirm}
            title={"Are you sure you want to delete your profile?"}
          />
        )}
        {deleteProfileInitiated && (
            <CustomSnackbar
                //handleClose={handleDeleteEmailSentClose}
                severenity={deleteMessageInfo && deleteMessageInfo.severenity}
                autoHideDuration={3500}
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
                open={deleteProfileInitiated}
            >
                {deleteMessageInfo && deleteMessageInfo.text}
            </CustomSnackbar>
        )}
      </>
    )
}

export default withRouter(DeleteProfileSection);