import React from "react";
import Jobs from "../modules/UserProfile/components/Jobs";

const JobsPage = () => {
    return(
        <section className="d-flex flex-column">
            <Jobs/>
        </section>
    )
}

export default JobsPage;