const SET_GDPR = "set-gdpr";

const setGDPR = payload => ({
  type: SET_GDPR,
  payload
});

export { setGDPR };

export { SET_GDPR };
