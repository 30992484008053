import React, { useEffect, useMemo } from "react";
import objectPath from "object-path";
// LayoutContext
import { useHtmlClassService } from "../_core/MetronicLayout";
// Import Layout components
import { Header } from "./header/Header";
import { HeaderMobile } from "./header-mobile/HeaderMobile";
import { Footer } from "./footer/Footer";
import { LayoutInit } from "./LayoutInit";
import { ScrollTop } from "./extras/ScrollTop";
import { useDispatch, useSelector } from "react-redux";
import { jobs } from "../../misc";
import { fetchJobPositions, setJobPositions, fetchJobApplications, fetchProfileSchema, fetchJobCategories, fetchJobLocations, fetchJobModes } from "../../../redux/modules/data/dataActions";
import { freshUserData, getNewTokens } from "../../../redux/modules/auth/authActions";
import { useHistory } from "react-router";
import CustomSnackbar from "../../../app/modules/UserProfile/components/CustomSnackbar";
import { setCustomNotificationText, setDialogNotificationText } from "../../../redux/modules/notifications/notificationsActions";
import ClosePopover from "./common/ClosePopover";

export const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const uiService = useHtmlClassService();
  const { isAccessTokenExpired, access_token, refresh_token } = useSelector(state => state.auth);
  const history = useHistory();
  const { customNotification, customNotificationType, dialogNotification } = useSelector(state => {
    return state.notifications
  });

  const itemsPerPage = 10;

  // Layout settings (cssClasses/cssAttributes)

  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      selfLayout: objectPath.get(uiService.config, "self.layout"),
      asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
      subheaderDisplay: objectPath.get(uiService.config, "subheader.display"),
      desktopHeaderDisplay: objectPath.get(uiService.config, "header.self.fixed.desktop"),
      contentCssClasses: uiService.getClasses("content", true),
      contentContainerClasses: uiService.getClasses("content_container", true),
      contentExtended: objectPath.get(uiService.config, "content.extended")
    };
  }, [uiService]);

  useEffect(() => {
    let isCancelled = false;

    if (!isCancelled) {
      dispatch(setJobPositions(jobs));
    }

    return () => (isCancelled = true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let isCancelled = false;

    if (!isCancelled) {
      if (isAccessTokenExpired) {
        const body = {
          grant_type: "refresh_token",
          client_id: process.env.REACT_APP_CLIENT_ID,
          client_secret: process.env.REACT_APP_CLIENT_SECRET_ID,
          access_token,
          refresh_token
        };

        dispatch(getNewTokens({ body, access_token }));
      }
    }

    return () => (isCancelled = true);
    // eslint-disable-next-line
  }, [isAccessTokenExpired]);

  useEffect(() => {
    dispatch(freshUserData(access_token));
    dispatch(fetchProfileSchema({ access_token }));
    dispatch(fetchJobPositions({ access_token }, itemsPerPage));
    dispatch(fetchJobApplications({ access_token }, itemsPerPage));
    dispatch(fetchJobCategories({ access_token }));
    dispatch(fetchJobLocations({ access_token }));
    dispatch(fetchJobModes({ access_token }));
    // eslint-disable-next-line
  }, [history.location.pathname]);

  const handleNotificationClose = () => {
    dispatch(setCustomNotificationText(null));
  }

  const handleClosePopover = () => {
    dispatch(setDialogNotificationText(null));
  }

  return layoutProps.selfLayout !== "blank" ? (
    <>
      {/*begin::Main*/}
      <HeaderMobile />
      <div className="d-flex flex-column flex-root">
        {/*begin::Page*/}
        <div className="d-flex flex-row flex-column-fluid page">
          {/*begin::Wrapper*/}
          <div className="d-flex flex-column flex-row-fluid wrapper metronic_wrapper" id="kt_wrapper">
            <Header />
            {/*general notification panel visible on all pages*/}
            {customNotification && (
              <CustomSnackbar
                handleClose={() => handleNotificationClose()}
                severenity={customNotificationType}
                autoHideDuration={3500}
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
                open={customNotification ? true : false}
              >
                {customNotification}
              </CustomSnackbar>
            )}
            {dialogNotification && (
              <ClosePopover
                isOpened={dialogNotification ? true : false}
                handleClosePopover={handleClosePopover}
                content={dialogNotification}
              />
            )}
            {/*begin::Content*/}
            <div id="kt_content" className={`content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid`}>
              {/*begin::Entry*/}
              {!layoutProps.contentExtended && (
                <div className="d-flex flex-column-fluid">
                  {/*begin::Container*/}
                  <div className={`${layoutProps.contentContainerClasses} metronic_route-container`}>{children}</div>
                  {/*end::Container*/}
                </div>
              )}

              {layoutProps.contentExtended && { children }}
              {/*end::Entry*/}
            </div>
            {/*end::Content*/}
            <Footer />
          </div>
          {/*end::Wrapper*/}
        </div>
        {/*end::Page*/}
      </div>
      <ScrollTop />
      {/* <StickyToolbar /> */}
      {/*end::Main*/}
      <LayoutInit />
    </>
  ) : (
    // BLANK LAYOUT
    <div className="d-flex flex-column flex-root">{children}</div>
  );
};
