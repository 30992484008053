import React from 'react';
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
//import CloudUploadIcon from "@material-ui/icons/CloudUpload";
//import CustomButton from "../../../../_metronic/layout/components/common/CustomButton";
import NatekDefaultAvatar from "../../../../_metronic/_images/NATEK-mark-color.png";

function ImageField({
    field,
    handleUploadFile,
    handleDelete,
    files,
    error,
    readonly
}) {
    const { label, parameters, name, type, id, required } = field;
    const { multiple, deletable } = parameters;
    const fieldId = name ? name : id;
    const fieldValue = files[fieldId];

    function previewFile(e, index) {
        const previewId = index ? `kt_profile_avatar-${index}` : 'kt_profile_avatar'
        const preview = document.getElementById(previewId);
        const file = e.target.files[0];
        const reader = new FileReader();
      
        reader.addEventListener("load", function () {
          // convert image file to base64 string
          preview.style.backgroundImage = "url(" + reader.result + ")";
        }, false);
      
        if (file) {
          reader.readAsDataURL(file);
        }
      }

    const handleFileChange = (e, index) => {
        handleUploadFile(e, name, multiple);
        previewFile(e, index);
    }

    const getUserPic = (coverPhoto) => {
        if (coverPhoto && coverPhoto.hash) {
            const imageUrl = `${process.env.REACT_APP_STORAGE_URL}/${coverPhoto.hash}`
            return imageUrl;
        }
        return toAbsoluteUrl(NatekDefaultAvatar);

    };

    const renderPreviewCard = (fileValue, index) => {
        return(
            <div
                key={index}
                className="image-input image-input-outline"
                id={index ? `kt_profile_avatar-${index}` : "kt_profile_avatar"}
                style={{
                backgroundImage: `url(${getUserPic(fileValue)})`
                }}
            >
                <div className="image-input-wrapper" /* style={{ backgroundImage: `${getUserPic()}` }} */ />
                {!readonly &&
                <label
                    className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                    data-action="change"
                    data-toggle="tooltip"
                    title=""
                    data-original-title="Change avatar"
                >
                <i className="fa fa-pen icon-sm text-muted"></i>
                <input 
                    type={type}
                    name={name}
                    onChange={(e)=>handleFileChange(e, index)}
                    //onChange={event => {onProfilePhotoChange(event, name)}}
                    accept=".png, .jpg, .jpeg" 
                />
                <input type="hidden" name="profile_avatar_remove" />
                </label>
                }
                {!readonly && deletable && fieldValue && ((!multiple && fieldValue.id) || (multiple && fieldValue[index]) ) && (
                    <>
                        <span
                            className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                            data-action="cancel"
                            data-toggle="tooltip"
                            title=""
                            data-original-title="Cancel avatar"
                        >
                            <i className="ki ki-bold-close icon-xs text-muted"></i>
                        </span>
                        <span
                            onClick={() => handleDelete(index, multiple, fieldId)}
                            className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                            data-action="remove"
                            data-toggle="tooltip"
                            title=""
                            data-original-title="Remove avatar"
                        >
                            <i className="ki ki-bold-close icon-xs text-muted"></i>
                        </span>
                    </>
                )}
            </div>
        )
    }

    const renderFilePreview = () => {
        const currentValue = files[name];
        if (multiple) {
            return currentValue.map((val, i)=>{
                return renderPreviewCard(val, i)
            })
        } else {
            return renderPreviewCard(currentValue);
        }
    }

    return (
        <React.Fragment>
            {/* begin: image info section */}
            <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                    {label}
                    {required && (<span className="required-identification"> *</span>)}
                </label>
                <div className="col-lg-9 col-xl-6">
                    {/* {(!showPreview) ? (
                        <div className="form-group fv-plugins-icon-container">
                        <input name={name} type={type} id={id} style={{ display: "none" }} onChange={(e)=>handleFileChange(e)} />
                        <label htmlFor={id}>
                          <CustomButton component="span" startIcon={<CloudUploadIcon />}>
                            Upload file
                          </CustomButton>
                        </label>
                      </div>
                    ) : renderFilePreview()} */}
                    {renderFilePreview()}
                    {error && (
                        <span  className="form-text error-text">
                            {error}
                        </span>
                    )}
                    {!readonly && <span className="form-text text-muted">{`Allowed file types: png, jpg, jpeg. Maximum file size: ${process.env.REACT_APP_FILE_SIZE} mb.`}</span>}
                </div>
            </div>
            {/* end: image section */}
        </React.Fragment>
    )
}

export default ImageField;