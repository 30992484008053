import { SET_GDPR } from "./gdprActions";

const initialState = {
  hasGDPR: false
};

const gdprReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_GDPR:
      return {
        ...state,
        hasGDPR: !state.hasGDPR
      };
    default:
      return state;
  }
};

export default gdprReducer;
