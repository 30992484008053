import { makeStyles } from "@material-ui/core";
import React from "react";

function JobDescriptionLink({ url }) {
  const styles = useStyles();

  return (
    <span>
      {url && (
        <a href={url} target="_blank" rel="noopener noreferrer" className={styles.jdLink}>
          <i className="fas fa-file"></i>
        </a>
      )}
    </span>
  );
}

const useStyles = makeStyles(theme => ({
  jdLink: {
    "&:hover": {
      color: "#c33f14"
    },
    "& i": {
      color: "inherit",
      fontSize: "19px"
    }
  }
}));

export default JobDescriptionLink;
