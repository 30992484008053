/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import NatekLogoPuzzle from "../../../../_images/NATEK-logo-puzzle.png";
import NatekBackground from "../../../../_images/NATEK-bck-2-zoomed.png";
import NatekDefaultAvatar from "../../../../_images/NATEK-mark-color.png";
import CustomButton from "../../common/CustomButton";
import { logoutUser } from "../../../../../redux/modules/auth/authActions";

export const UserProfileDropdown = () => {
  const dispatch = useDispatch();
  const { user, access_token } = useSelector(state => state.auth);

  const handleLogout = () => dispatch(logoutUser(access_token));

  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle as={DropdownTopbarItemToggler} id="dropdown-toggle-user-profile">
        <div className={"btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"}>
          <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Hi,</span>
          <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">{user && user.first_name && user.first_name}</span>
          <span className="symbol symbol-35 metronic_symbol-container">
            <span className="symbol-label font-size-h5 font-weight-bold">{user && user.first_name && user.first_name[0]}</span>
          </span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        <>
          <div
            className="d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top"
            style={{
              backgroundImage: `url(${NatekBackground})`
            }}
          >
            <div className="symbol bg-white-o-15 mr-3">
              <img alt="Pic" className="hidden metronic_user-img" src={user && user.profile_photo ? `${process.env.REACT_APP_STORAGE_URL}/${user.profile_photo.hash}` : NatekDefaultAvatar} />
            </div>
            <section className="metronic_user-information">
              <div className="text-white m-0 flex-grow-1 mr-3 font-size-h2 text-muted metronic_fullname">
                {user && user.first_name && user.family_name && `${user && user.first_name} ${user && user.family_name}`}
              </div>
              <div className="text-white m-0 flex-grow-1 mr-3 font-size-h6 metronic_occupation">{user && user.occupation && user.occupation}</div>
              <div className="text-white m-0 flex-grow-1 mr-3 font-size-h6 d-flex align-items-center flex-wrap">
                <i className="flaticon2-mail mr-2 metronic_email-icon"></i>
                <span className="metronic_email">{user && user.email && user.email}</span>
              </div>
            </section>
          </div>
        </>

        <div className="navi navi-spacer-x-0 pt-5">
          <Link to="/profile" className="navi-item px-8 cursor-pointer">
            <div className="navi-link pl-2">
              <div className="navi-icon mr-2">
                <img src={NatekLogoPuzzle} alt="Natek Logo Puzzle" className="metronic_nate-logo-puzzle" />
              </div>
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer">
                  <span className="navi-text font-size-lg">Profile details </span>
                </div>
                <div className="text-muted">Account settings and more</div>
              </div>
            </div>
          </Link>

          <div className="navi-footer  px-8 py-5">
            <CustomButton onClick={handleLogout}>
              <Link to="/auth/login" className="font-weight-bold">
                Sign Out
              </Link>
            </CustomButton>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};
