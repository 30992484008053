import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import CustomButton from "../../../../_metronic/layout/components/common/CustomButton";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { timeToClose } from "../../../../_metronic/misc";
import { forgottenPassword, removeFailedNotification, resetForgottenPasswordData } from "../../../../redux/modules/auth/authActions";

const initialValues = {
  email: ""
};

const ForgotPassword = props => {
  const { intl } = props;
  const dispatch = useDispatch();
  const { hasErrorMessage, emailForgottenPassword } = useSelector(state => state.auth);
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const successMsg = emailForgottenPassword && emailForgottenPassword.message;

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      )
  });

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      setLoading(true);

      dispatch(
        forgottenPassword({ email: values.email, client_id: process.env.REACT_APP_CLIENT_ID, client_secret: process.env.REACT_APP_CLIENT_SECRET_ID })
      );
    }
  });

  const handleClose = () => dispatch(removeFailedNotification());

  const closeAlert = state => {
    if (state) {
      setLoading(true);

      setTimeout(() => {
        handleClose();
        setLoading(false);
      }, timeToClose);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    let isCancelled = false;

    if (!isCancelled) {
      closeAlert(hasErrorMessage);
    }

    return () => (isCancelled = true);
    // eslint-disable-next-line
  }, [hasErrorMessage]);

  useEffect(() => {
    let isCancelled = false;

    if (!isCancelled) {
      closeAlert(emailForgottenPassword);
    }

    return () => (isCancelled = true);
    // eslint-disable-next-line
  }, [emailForgottenPassword]);

  useEffect(() => {
    if (successMsg) {
      setTimeout(() => {
        dispatch(resetForgottenPasswordData());
        setRedirect(true);
      }, timeToClose);
    }
  }, [successMsg, dispatch]);

  if (redirect) {
    return <Redirect to="/auth/login" />
  }

  return (
    <>
      {
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Forgotten Password ?</h3>
            <div className="text-muted font-weight-bold">Enter your email to reset your password</div>
          </div>

          {hasErrorMessage && (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">{hasErrorMessage}</div>
            </div>
          )}

          {successMsg && (
            <div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
              <div className="alert-text font-weight-bold">{successMsg}</div>
            </div>
          )}

          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">{formik.status}</div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <input
                type="email"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses("email")}`}
                name="email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <CustomButton
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={loading}
              >
                <span className={loading ? "mr-1" : ""}>Submit</span>
                {loading && <span className="mr-4 spinner spinner-white"></span>}
              </CustomButton>
              <Link to="/auth/login">
                <CustomButton type="button" id="kt_login_forgot_cancel" className="font-weight-bold px-9 py-4 my-3 mx-4">
                  Back
                </CustomButton>
              </Link>
            </div>
          </form>
        </div>
      }
    </>
  );
};

export default injectIntl(ForgotPassword);