import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useParams } from "react-router";
import { Redirect, useHistory } from "react-router-dom";
import * as Yup from "yup";
import CustomButton from "../../../../_metronic/layout/components/common/CustomButton";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { timeToClose } from "../../../../_metronic/misc";
import {
  //FAILED_VALIDATE_TOKEN_RESET_PASSWORD,
  removeFailedNotification,
  resetPassword as resetPasswordAPICall,
  validateTokenRP
} from "../../../../redux/modules/auth/authActions";

const initialValues = {
  password: "",
  changepassword: ""
};

const ResetPassword = props => {
  const { intl } = props;
  const dispatch = useDispatch();
  const { tokenValidationError,/*  status, */ validateTokenResetPassword, resetPassword } = useSelector(state => state.auth);
  const [loading, setLoading] = useState(false);
  const [isRequested/* , setIsRequested */] = useState(false);
  const [email, setEmail] = useState(null);
  const { token } = useParams();
  const history = useHistory();

  //const isFailedValidateTokenError = status === FAILED_VALIDATE_TOKEN_RESET_PASSWORD ? true : false;
  const successMsg = resetPassword && resetPassword.message;

  const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, "Minimum 8 symbols")
      .max(50, "Maximum 50 symbols")
      .matches(/^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/, intl.formatMessage({ id: "AUTH.VALIDATION.PASSWORD_REGEX" }))
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      ),
    changepassword: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      )
      .when("password", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf([Yup.ref("password")], "Password and Confirm Password didn't match")
      })
  });

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ResetPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      setLoading(true);

      const data = {
        email,
        token,
        password: values.password,
        password_confirmation: values.password
      };

      dispatch(resetPasswordAPICall(data));
    }
  });

  const handleClose = () => dispatch(removeFailedNotification());

  const closeAlert = (state, label) => {
    if (state) {
      setLoading(true);

      setTimeout(() => {
        handleClose();
        setLoading(false);
        label === "reset-password" && history.push("/auth/login");
      }, timeToClose);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    let isCancelled = false;

    if (!isCancelled) {
      closeAlert(tokenValidationError);
    }

    return () => (isCancelled = true);
    // eslint-disable-next-line
  }, [tokenValidationError]);

  useEffect(() => {
    let isCancelled = false;

    if (!isCancelled) {
      closeAlert(resetPassword, "reset-password");
    }

    return () => (isCancelled = true);
    // eslint-disable-next-line
  }, [resetPassword]);

  useEffect(() => {
    let isCancelled = false;

    if (!isCancelled) {
      dispatch(validateTokenRP({ token }));
    }

    return () => (isCancelled = true);
    // eslint-disable-next-line
  }, [token]);

  useEffect(() => {
    let isCancelled = false;

    if (!isCancelled) {
      if (validateTokenResetPassword.data) {
        const { email } = validateTokenResetPassword.data;

        setEmail(email);
      }
    }

    return () => (isCancelled = true);
  }, [validateTokenResetPassword]);

  return (
    <>
      {tokenValidationError && <Redirect to="/auth/login" />}

      {!isRequested && !tokenValidationError && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Reset your password</h3>
            <div className="text-muted font-weight-bold">Enter your new password and confirm it</div>
          </div>

          {successMsg && (
            <div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
              <div className="alert-text font-weight-bold">{successMsg}</div>
            </div>
          )}

          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">{formik.status}</div>
              </div>
            )}
            {/* begin: Password */}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Password"
                type="password"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses("password")}`}
                name="password"
                {...formik.getFieldProps("password")}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.password}</div>
                </div>
              ) : null}
            </div>
            {/* end: Password */}
            {/* begin: Confirm Password */}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Confirm Password"
                type="password"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses("changepassword")}`}
                name="changepassword"
                {...formik.getFieldProps("changepassword")}
              />
              {formik.touched.changepassword && formik.errors.changepassword ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.changepassword}</div>
                </div>
              ) : null}
            </div>
            {/* end: Confirm Password */}
            <div className="form-group d-flex flex-wrap flex-center">
              <CustomButton
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={loading}
              >
                <span className={loading ? "mr-2" : ""}>Submit</span>
                {loading && <span className="mr-4 spinner spinner-white"></span>}
              </CustomButton>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default injectIntl(ResetPassword);
