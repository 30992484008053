import React from "react";

const InfoSection = ({
    name
}) => {
  return (
        <div className="row">
            <label className="col-xl-3"></label>
            <div className="col-lg-9 col-xl-6">
            <h5 className="font-weight-bold mb-6">{name}</h5>
            </div>
        </div>
      )
};

export default InfoSection;
