import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import { Grid } from '@material-ui/core';
import { useSelector, useDispatch } from "react-redux";
import { fetchJobPositions, setJobsPage } from '../../../../redux/modules/data/dataActions';
import { isMobilePhonePortrait } from '../../../helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 20,
    paddingBottom: 20,
    minWidth: 260,
    ['@media (max-width:300px)']: { // eslint-disable-line no-useless-computed-key
      marginLeft: '-20px'
    },
    gap: 20,
  }
}));

function JobsPagination({searchValue, categoriesValue, locationsValue, modesValue, jobsPerPage = 10, updateJobsPerPage}) {
  const classes = useStyles();
  const { access_token } = useSelector(state => state.auth);
  const { lastJobsPage, jobsPage } = useSelector(state => state.data);
  const dispatch = useDispatch();

  const onPageChange = (e, newPage) => {
    dispatch(setJobsPage(newPage));
    dispatch(fetchJobPositions({ access_token }, jobsPerPage, newPage, searchValue, categoriesValue, locationsValue, modesValue));
  }

  return (
    <Grid container justify='center' className={classes.root}>
      <Pagination
          size={isMobilePhonePortrait() ? "small" : "medium"}
          page={jobsPage}
          count={lastJobsPage}
          color="primary"
          onChange={onPageChange}
      />

      <div className="d-flex align-items-center ml-xl-20">
        <span className="display-block mr-5">Jobs per page:</span>

        <select
            className="border-0 p-1 gray-background"
            onChange={updateJobsPerPage}
            value={jobsPerPage}>
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>
    </Grid>
  );
}

export default JobsPagination;